<template>
  <div class="checkOrder">
    <el-dialog
      v-if="isshowDialog"
      :modal="false"
      :close-on-click-modal="false"
      title="订单详情"
      top="5vh"
      :visible.sync="isshowDialog"
      fullscreen
      @close="close"
    >
      <div v-loading="loading" class="wrap">
        <el-alert
          :closable="false"
          :title="titleTip"
          type="success"
          style="width: 86%; margin-top: -70px; margin-left: 80px"
        >
          {{ data.order_info && data.order_info.cancle_str }}
        </el-alert>
        <!-- <div class="orderStatus">{{ orderStatus }}</div> -->
        <el-form ref="form" :model="form" label-width="80px" :inline="true">
          <el-form-item label="购货方" prop="cust_name">
            <el-input v-model.trim="form.cust_name" disabled></el-input>
          </el-form-item>
          <el-form-item label="联系人" prop="boss">
            <el-input v-model.trim="form.boss" disabled></el-input>
          </el-form-item>
          <el-form-item label="地址" prop="address" class="dz">
            <el-input
              v-model.trim="form.address"
              disabled
              style="width: 380px"
            ></el-input>
          </el-form-item>

          <el-form-item label="仓库" prop="depot_name">
            <el-input v-model.trim="form.depot_name" disabled></el-input>
          </el-form-item>

          <el-form-item label="送货人" prop="deliver_name">
            <el-input v-model.trim="form.deliver_name" disabled></el-input>
          </el-form-item>
          <el-form-item label="业务员" prop="staff_name">
            <el-input v-model.trim="form.staff_name" disabled></el-input>
          </el-form-item>

          <el-form-item label="调拨单" prop="allot_code">
            <el-input v-model.trim="form.allot_code" disabled></el-input>
          </el-form-item>
          <el-form-item label="派单" prop="delivery_time">
            <el-input v-model.trim="form.delivery_time" disabled></el-input>
          </el-form-item>
          <!--          <el-form-item
            label="扫码录入"
            prop="goods_code"
            style="margin-left: 20px"
          >
            <el-input
              v-model.trim="form.goods_code"
              :disabled="discode"
              clearable
              placeholder="使用扫码枪扫码添加商品"
              @keyup.enter.native="handlerkey"
            ></el-input>
          </el-form-item>-->
          <!-- <el-form-item label="调拨单" prop="allot_code">
            <el-input v-model.trim="form.allot_code" disabled></el-input>
          </el-form-item> -->
          <el-form-item v-if="followuser.length != 0" label="随车人">
            <el-select
              v-model="form.follow_users"
              placeholder="随车人员"
              clearable
              multiple
              disabled
            >
              <el-option
                v-for="item_ in followuser"
                :key="item_.id"
                :label="item_.user_name"
                :value="item_.id"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-form>
        <!-- <div class="setFilter">
          <el-popover popper-class="custom-table-checkbox" trigger="hover">
            <el-checkbox-group v-model="checkList">
              <el-checkbox
                v-for="(item, index) in columns"
                :key="index"
                :label="item.label"
              ></el-checkbox>
            </el-checkbox-group>
            <el-button slot="reference">
              <vab-remix-icon icon="settings-line" />
            </el-button>
          </el-popover>
        </div> -->
        <el-table ref="middleTable" stripe :data="middleTableData">
          <!-- 序号 -->
          <el-table-column align="center" width="50">
            <!-- <template slot="header">
              <el-popover popper-class="custom-table-checkbox" trigger="hover">
                <el-checkbox-group v-model="checkList">
                  <el-checkbox
                    v-for="_item in columns"
                    :key="_item.prop"
                    :label="_item.label"
                  ></el-checkbox>
                </el-checkbox-group>
                <el-button slot="reference" type="text">
                  <vab-remix-icon icon="settings-line" />
                </el-button>
              </el-popover>
            </template> -->
            <template #default="{ $index }">
              <span>{{ $index + 1 }}</span>
            </template>
          </el-table-column>
          <!-- <el-table-column prop="" align="center" label="" width="60px">
            <template #default="{ $index }">
              <span>{{ $index + 1 }}</span>
            </template>
          </el-table-column> -->
          <!-- 选择框 -->
          <el-table-column
            align="center"
            type="selection"
            width="45"
          ></el-table-column>
          <el-table-column
            v-for="(item, index) in finallyColumns"
            :key="index"
            align="center"
            :label="item.label"
            :sortable="item.sortable"
            :width="item.width"
          >
            <template #default="{ $index, row }">
              <!-- 11111 -->
              <template v-if="!row.edit">
                <span v-if="item.label == '到货数量'">
                  <el-input
                    v-model="row.quantity"
                    :disabled="
                      (row.goods_type != 1 || row.goods_type != 17) &&
                      row.goods_name == ''
                    "
                    onkeyup="value=value.replace(/[^\d.]/g,'') "
                    @input="moneyN(row, $index), utilsF()"
                  ></el-input>
                </span>
                <span v-else-if="item.label == '类型'">
                  <el-select v-model="row.goods_type" disabled>
                    <el-option
                      v-for="item__ in row.arr_goods_type"
                      :key="item__.id"
                      :label="item__.name"
                      :value="item__.id"
                    ></el-option>
                  </el-select>
                </span>
                <span v-else-if="item.label == '单位'">
                  {{ row.unit_name }}
                </span>
                <span v-else-if="item.label == '生产日期'">
                  <!-- <el-input v-model="row.goods_production_date"></el-input> -->
                  <el-date-picker
                    v-model="row.goods_production_date"
                    value-format="yyyy-MM-dd"
                    style="width: 130px"
                  ></el-date-picker>
                </span>
                <span v-else-if="item.label == '备注'">
                  <el-input v-model="row.info"></el-input>
                </span>
                <div v-else-if="item.label == '条码'">
                  {{ currUnitCode(row) }}
                </div>
                <span v-else-if="item.label == '车存'">
                  <!-- <el-input v-model="row.balance_count_text"></el-input> -->
                  {{ row.balance_count_text }}
                </span>
                <span v-else-if="item.label == '单价'">
                  <el-input
                    v-model="row.goods_price"
                    :disabled="
                      ((row.goods_type != 1 ||
                        row.goods_type != 17 ||
                        row.goods_type != 6) &&
                        row.goods_name == '') ||
                      isEditPrice == 0
                    "
                    @input="moneyN(row, $index), utilsF()"
                  ></el-input>
                </span>
                <span v-else>
                  {{ row[item.prop] }}
                </span>
              </template>

              <!-- else -->
              <template v-else>
                <span v-if="item.label == '类型'">
                  <el-select
                    v-model="row.goods_type"
                    placeholder="选择类型"
                    @change="utilsF(), handleTypeChange($event, row)"
                  >
                    <el-option
                      v-for="item__ in row.arr_goods_type"
                      :key="item__.id"
                      :label="item__.name"
                      :value="item__.id"
                    ></el-option>
                  </el-select>
                </span>
                <div v-else-if="item.label == '条码'">
                  {{ currUnitCode(row) }}
                </div>
                <span v-else-if="item.label == '商品名称'">
                  <!-- <el-autocomplete
                    v-model="row.goods_name"
                    class="inline-input"
                    popper-class="selectDC"
                    :fetch-suggestions="querySearch2"
                    value-key="goods_name"
                    placeholder="请输入商品名称、简拼"
                    @select="handleSelect($index, row, $event)"
                    @input="addRow($index, row, $event)"
                  >
                    <i
                      slot="suffix"
                      class="el-input__icon el-icon-search"
                      @click="iconClick($index, row)"
                    ></i>
                    <template slot-scope="{ item2 }">
                      <div class="wrap" style="display: flex">
                        <div
                          class="name"
                          style="width: 200px; margin-right: 120px"
                        >
                          {{ item2.goods_name }}
                        </div>
                        <span class="addr">{{ item2.specs }}</span>
                      </div>
                    </template>
                  </el-autocomplete> -->
                  <goods-search
                    ref="goodsSearch"
                    :f-key="row.goods_name"
                    :cust-id="form.cust_id"
                    :rowf="row"
                    :kouwei="row.child"
                    @add-rows="addRows"
                    @select-goods-all="handleSelect($event, row, $index)"
                    @change-kouwei="changeKouwei($event, row)"
                  ></goods-search>
                </span>
                <span v-else-if="item.label == '单位'">
                  <el-select
                    v-model="row.unit_id"
                    placeholder="选择单位"
                    :disabled="row.goods_name == ''"
                    @change="unitChange($event, row), utilsF()"
                  >
                    <el-option
                      v-for="item1_ in row.arr_unit"
                      :key="item1_.id"
                      :label="item1_.unit_name"
                      :value="item1_.id"
                    ></el-option>
                  </el-select>
                </span>
                <span v-else-if="item.label == '单价'">
                  <el-input
                    v-model="row.goods_price"
                    :disabled="row.goods_name == '' || isEditPrice == 0"
                    @input="moneyN(row, $index), utilsF()"
                  ></el-input>
                </span>
                <span v-else-if="item.label == '订购数量'">
                  <!-- <el-input v-model="" disabled></el-input> -->
                  {{ row.pre_quantity }}
                </span>
                <span v-else-if="item.label == '到货数量'">
                  <el-input
                    v-model="row.quantity"
                    :disabled="row.activity_id != 0 || row.goods_name == ''"
                    @input="moneyN(row, $index), utilsF()"
                  ></el-input>
                </span>
                <span v-else-if="item.label == '车存'">
                  <!-- <el-input v-model="row.balance_count_text"></el-input> -->
                  {{ row.balance_count_text }}
                </span>
                <span v-else-if="item.label == '金额'">
                  <div
                    v-if="
                      row.goods_type == 2 ||
                      row.goods_type == 10 ||
                      row.goods_type == 11 ||
                      row.goods_type == 3 ||
                      row.goods_type == 9 ||
                      row.goods_type == 12 ||
                      row.goods_type == 13 ||
                      row.goods_type == 16
                    "
                  >
                    0
                  </div>
                  <div v-else>
                    {{ row.goods_money }}
                  </div>
                  <!-- <span v-if="row.goods_type == 2 || row.goods_type == 3">
                    0
                  </span>
                  <span v-else>{{ row.goods_money }}</span> -->
                </span>
                <span v-else-if="item.label == '生产日期'">
                  <!-- <el-input v-model="row.goods_production_date"></el-input> -->
                  <el-date-picker
                    v-model="row.goods_production_date"
                    value-format="yyyy-MM-dd"
                    style="width: 130px"
                  ></el-date-picker>
                </span>
                <span v-else-if="item.label == '备注'">
                  <el-input v-model="row.info"></el-input>
                </span>
                <span v-else-if="item.label == '规格'">
                  {{ row.specs }}
                </span>
                <span v-else>
                  <!-- {{ row[item.prop] }} -->
                  <el-input
                    v-model="row[item.prop]"
                    onkeyup="value=value.replace(/[^\d.]/g,'') "
                  ></el-input>
                </span>
              </template>

              <!-- 11111 -->
            </template>
          </el-table-column>
          <el-table-column
            align="center"
            label="操作"
            fixed="right"
            width="160"
          >
            <template #default="{ $index, row }">
              <!-- <el-button type="text" @click.native.prevent="addRow(row)">
              添加
            </el-button> -->
              <el-button
                type="text"
                @click.native.prevent="copyRow($index, row)"
              >
                复制
              </el-button>
              <el-button
                type="text"
                @click.native.prevent="deleteRow($index, row)"
              >
                删除
              </el-button>
            </template>
          </el-table-column>
        </el-table>
        <!-- 底部输入框 -->
        <el-form
          ref="form2"
          :model="form"
          label-width="120px"
          :inline="true"
          style="margin-top: 15px"
        >
          <!--          <div v-if="style == 1">-->
          <el-form-item label="备注" prop="remark" style="width: 100%">
            <el-input v-model="form.remark" style="width: 530px"></el-input>
          </el-form-item>
          <el-form-item label="订购总额" prop="total_amount">
            <el-input v-model.trim="form.total_amount" disabled></el-input>
          </el-form-item>
          <el-form-item label="销售金额" prop="sale_amount">
            <el-input v-model.trim="form.sale_amount" disabled></el-input>
          </el-form-item>
          <el-form-item label="使用预存款" prop="deposit_amount">
            <el-input v-model.trim="form.deposit_amount" disabled></el-input>
          </el-form-item>
          <el-form-item label="还货金额" prop="return_amount">
            <el-input v-model.trim="form.return_amount" disabled></el-input>
          </el-form-item>
          <el-form-item label="优惠" prop="discounted_amount">
            <el-input
              v-model.trim="form.discount_amount"
              @input="yhInputF"
            ></el-input>
          </el-form-item>
          <el-form-item label="优惠后" prop="discounted_amount">
            <el-input
              v-model.trim="form.discounted_amount"
              @input="yhhInputF"
            ></el-input>
          </el-form-item>

          <el-form-item label="退货退款" prop="refund_amount">
            <el-input v-model.trim="form.refund_amount" disabled></el-input>
          </el-form-item>
          <el-form-item label="退货金额" prop="refund_goods_amount">
            <el-input
              v-model.trim="form.refund_goods_amount"
              disabled
            ></el-input>
          </el-form-item>
          <el-form-item label="退货方式" prop="refund_type">
            <!-- <el-input v-model="form.refund_type_text" disabled></el-input> -->
            <el-select
              v-model="form.refund_type"
              placeholder="退货方式"
              style="width: 186px"
              @change="utilsF"
            >
              <el-option
                v-for="item in data.arr_refund_type"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item v-if="style == 2" label="兑奖现金:" prop="prize_amount">
            <el-input
              v-model.trim="form.prize_amount"
              @input="rehandler"
            ></el-input>
          </el-form-item>
          <el-form-item v-if="style == 3" label="兑付现金:" prop="cash_amount">
            <el-input
              v-model.trim="form.cash_amount"
              @input="rehandler"
            ></el-input>
          </el-form-item>
          <el-form-item v-if="form.is_fun" label="费用抵扣" prop="thtk">
            <el-input v-model.trim="form.support_amount"></el-input>
          </el-form-item>
          <el-form-item v-if="form.is_fun">
            <el-select
              v-model="form.fun_ids"
              :placeholder="'请选费用' + `（合计${funds_zong}）`"
              style="width: 186px"
              multiple
              collapse-tags
              @change="handlerfydk"
            >
              <el-option
                v-for="item_ in funselect"
                :key="item_.id"
                :label="item_.funds_type + `(金额${item_.left_amount})`"
                :value="item_.id"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="应收款" prop="receiv_money">
            <el-input v-model.trim="form.receiv_money" disabled></el-input>
          </el-form-item>
          <el-form-item label="结算方式" prop="account_type_text">
            <el-input v-model.trim="form.account_type_text" disabled></el-input>
          </el-form-item>
          <el-form-item label="销售收款">
            <div style="display: flex">
              <el-input v-model.trim="form.staff_collect" disabled></el-input>
            </div>
          </el-form-item>
          <el-form-item label="派送收款">
            <el-input v-model.trim="form.deliver_collect" disabled></el-input>
          </el-form-item>
          <!--          </div>
          <div v-else-if="style == 2">
            <el-form-item label="兑奖现金:" prop="prize_amount">
              <el-input v-model.trim="form.prize_amount"></el-input>
            </el-form-item>
          </div>
          <div v-else-if="style == 3">
            <el-form-item label="兑付现金:" prop="cash_amount">
              <el-input v-model.trim="form.cash_amount"></el-input>
            </el-form-item>
          </div>-->
        </el-form>

        <!-- 小表格 -->
        <el-table
          v-if="style == 1"
          ref="xTable"
          stripe
          :data="xTableData"
          show-summary
        >
          <el-table-column
            align="center"
            label="收款方式"
            width="auto"
            prop="pay_type_name"
          ></el-table-column>
          <el-table-column
            align="center"
            label="金额"
            width="auto"
            prop="amount"
          ></el-table-column>
          <el-table-column
            align="center"
            label="收款人"
            width="auto"
            prop="user_name"
          ></el-table-column>
          <el-table-column
            align="center"
            label="时间"
            width="auto"
            prop="create_at"
          ></el-table-column>
        </el-table>
        <!-- 底部按钮 -->
        <div
          slot="footer"
          class="dialog-footer"
          style="margin-top: 15px; text-align: right"
        >
          <el-button type="primary" @click="showSun">派单到货</el-button>

          <el-button>打印</el-button>
          <el-button @click="close">取 消</el-button>
        </div>
      </div>
      <arrival-sun
        ref="arrivalSun"
        :data="{ a: form.receiv_money, b: form.staff_collect }"
        @save-end="handleSendOrder"
      ></arrival-sun>
    </el-dialog>
  </div>
</template>

<script>
  import _ from 'lodash'
  import { yhhInput, utils, yhInput, receiv } from '@/utils/AOform.js'
  import GoodsSearch from '@/baseComponents/goodsSearch'
  import { getInputList } from '@/api/goodsSearchInput'
  import {
    getAdvanceOrderEditTableList,
    sendOrderArrival,
  } from '@/api/advanceOrder'
  import ArrivalSun from './components/arrivalSun'
  import { postAction } from '@/api/Employee'
  export default {
    name: 'Arrival',
    components: {
      ArrivalSun,
      GoodsSearch,
    },
    data() {
      return {
        //随车人
        followuser: [],
        id: Number,
        style: 1,
        loading: true,
        data: [],
        orderCode: '',
        isshowDialog: false,
        funds_zong: '',
        funselect: [],
        form: {
          fun_ids: [],
          // cust_name: '老牛烧烤',
          // dz: '河北省石家庄市裕华区XXXXXXXXXX',
          // boss: '牛老板 15533315521',
          // ywy: '张三 15566321123',
          // chck: '总仓',
          // shr: '张师傅      冀A3232323',
          // dbd: 'DB202012321321321  （已审核)',
          // pd: '2020.04.25  15:20:30',
          // dh: '2020.04.25   18:10:15',
        },
        title: '订单详情',
        orderStatus: '状态',
        // 中间表格
        checkList: [
          '类型',
          '商品名称',
          '规格',
          '条码',
          '单位',
          '单价',
          '订购数量',
          '到货数量',
          '车存',
          '金额',
          '生产日期',
          '备注',
        ],
        columns: [
          {
            order: 2,
            label: '类型',
            width: '120px',
            prop: 'type',
            sortable: false,
          },
          {
            order: 3,
            label: '商品名称',
            width: '180',
            prop: 'goods_name_print',
            sortable: false,
          },
          {
            order: 3.1,
            label: '规格',
            width: '130',
            prop: 'specs',
            sortable: false,
          },
          {
            order: 3.2,
            label: '条码',
            width: '130',
            prop: 'unit_code',
            sortable: false,
          },
          {
            order: 4,
            label: '单位',
            width: '110', //'95',
            prop: 'units',
            sortable: false,
          },
          {
            order: 5,
            label: '单价',
            width: '100',
            prop: 'goods_price',
            sortable: false,
          },
          {
            order: 6,
            label: '订购数量',
            width: '100',
            prop: 'pre_quantity',
            sortable: false,
          },
          {
            order: 7,
            label: '到货数量',
            width: '110',
            prop: 'pre_quantity',
            sortable: false,
          },
          {
            order: 7.5,
            label: '车存',
            width: '110',
            prop: 'depot_stock_num_text',
            sortable: false,
          },
          {
            order: 8,
            label: '金额',
            width: '100',
            prop: 'goods_money',
            sortable: false,
          },
          {
            order: 9,
            label: '生产日期',
            width: '160',
            prop: 'goods_production_date',
            sortable: false,
          },
          {
            order: 10,
            label: '备注',
            width: '100',
            prop: 'info',
            sortable: false,
          },
        ],
        middleTableData: [
          // {
          //   type: '12312312',
          //   goods_name: '12312312',
          //   units: '12312312',
          //   price: '12312312',
          //   quantity: '12312312',
          //   arrival_num: '12312312',
          //   moneny: '12312312',
          //   production_date: '12312312',
          //   remark: '12312312',
          // },
        ],
        xTableData: [],
        // 底部输入框
        jsfsList: [
          // {
          //   lable: '货到付款',
          //   value: '货到付款',
          // },
          // {
          //   lable: '账期结算',
          //   value: '账期结算',
          // },
        ],
      }
    },

    computed: {
      discode() {
        if (this.form.cust_id != '') {
          return false
        } else {
          return true
        }
      },

      isEditPrice() {
        return localStorage.getItem('isEditPrice')
      },
      titleTip() {
        return '单号:' + this.orderCode + ' 下单时间： ' + this.form.sale_time
      },
      finallyColumns() {
        let finallyArray = []
        this.checkList.forEach((checkItem) => {
          finallyArray.push(
            this.columns.filter((item) => item.label === checkItem)[0]
          )
        })
        console.log(finallyArray)
        return _.sortBy(finallyArray, (item) => item.order)
      },
    },
    watch: {
      // 'form.receiv_money'(val) {
      //   let c = JSON.parse(JSON.stringify(this.form.receiv_money))
      //   if (this.form.account_type == 1) {
      //     this.xssk[0].money = Number(c).toFixed(2)
      //   } else {
      //     this.xssk[0].money = 0
      //   }
      // },
      isshowDialog(val) {
        if (val) {
          console.log('到货')
          this.getData()
        } else {
          this.loading = true
        }
      },
      'form.support_amount'(val) {
        utils(this.middleTableData, this, 3)
      },
    },
    mounted() {
      this.handlerfollowuserlist()
    },
    methods: {
      currUnitCode(row) {
        if (row.child && row.child.length > 0) {
          console.log(row.child, row, '口味有')
          let a = row.child.filter((i) => i.id == row.goods_child_id)[0]
          return a ? a.small_unit_code : ''
          // return ''
        } else {
          if (row.arr_unit && row.arr_unit.length > 0) {
            let a = row.arr_unit.filter(
              (i) => (i.unit_id || i.id) == row.unit_id
            )[0].unit_code
            return a
          } else {
            return ''
          }
        }
      },
      handlerfydk(v) {
        let fydk = 0
        console.log(v)
        v.map((list) => {
          fydk += Number(
            this.funselect.filter((item) => item.id == list)[0].left_amount
          )
        })
        this.form.support_amount = fydk.toFixed(2)
      },
      rehandler() {
        receiv(this)
      },
      async handlerkey() {
        if (this.form.goods_code) {
          const { data } = await getInputList({
            goods_code: this.form.goods_code,
            depot_id: this.form.depot_id,
            custom_id: this.form.cust_id,
          })
          if (data.length > 0) {
            let goodsdata = data.map((item, index) => {
              try {
                item.arr_unit.forEach((unit) => {
                  if (unit.is_default == 2) {
                    item.unit_id = unit.id
                    item.goods_price = unit.sell_price
                    item.unit_name = unit.unit_name
                  }
                })
                item.goods_type = 1
                item.type = '销常'
              } catch (err) {}
              return item
            })
            if (
              this.middleTableData.length <= 1 &&
              this.middleTableData[0].goods_name == ''
            ) {
              goodsdata[0].quantity += 1
              this.middleTableData.splice(
                this.middleTableData.length - 1,
                0,
                goodsdata[0]
              )
            } else {
              var goodstow = false
              this.middleTableData.forEach((list) => {
                if (list.goods_name != '') {
                  if (list.param_code == goodsdata[0].param_code) {
                    list.quantity += 1
                    goodstow = true
                  }
                }
              })
              if (!goodstow) {
                goodsdata[0].quantity += 1
                this.middleTableData.splice(
                  this.middleTableData.length - 1,
                  0,
                  goodsdata[0]
                )
              }
            }
          }
          this.form.goods_code = ''
        }
      },
      async handlerfollowuserlist() {
        return await postAction('saleAdmin/order-pre/option', {}).then(
          (res) => {
            this.followuser = res.data.order_follow_users
          }
        )
      },
      // 获取接口信息
      async getData() {
        let { data } = await getAdvanceOrderEditTableList({
          id: this.id,
          is_arrival: 1,
        })

        if (data.order_info.is_fun == 1) {
          await postAction('/saleAdmin/order-pre/funds-option', {
            cust_id: data.order_info.cust_id,
            order_id: this.id,
          }).then((res) => {
            this.funselect = res.data.bill_list
            this.funds_zong = res.data.funds_zong
          })
        }
        console.log('到货信息')
        console.log(data.order_info)
        // top 表单
        this.data = data
        if (data.order_info.check_status == 0) {
          this.orderStatus = data.order_info.bill_status_text
        } else {
          this.orderStatus = data.order_info.check_status_text
        }
        // 如果check_status =0   使用 bill_status_text

        //  check_status =1  使用check_status_text
        this.xTableData = data.arr_collect
        console.log(this.data)
        // 结算方式list
        // this.jsfsList = data.arr_account_type
        this.form = data.order_info
        if (this.form.follow_users != '') {
          this.form.follow_users = data.order_info.follow_users.split(',')
        } else {
          this.form.follow_users = []
        }
        this.orderCode = this.form.bill_code
        this.form.boss = this.form.boss + ' ' + this.form.mobile
        // 表格
        data.order_detail_info.forEach((item) => {
          this.$set(item, 'quantity2', item.quantity)
        })
        this.middleTableData = data.order_detail_info
        this.middleTableData.forEach((i) => {
          if (i.arrive_time != '-') {
            i.quantity = JSON.parse(JSON.stringify(i.quantity))
          } else {
            i.quantity = JSON.parse(JSON.stringify(i.pre_quantity))
          }
        })

        // 表格添加一行
        this.middleTableData.push({
          activity_code: '',
          activity_id: 0,
          activity_type: 0,
          balance_count: 0,
          depot_stock_num: 0,
          edit: true,
          goods_child_id: null,
          goods_money: '',
          goods_name: '',
          goods_price: '',
          goods_production_date: '',
          goods_type: '',
          info: '',
          money: '',
          quantity: '',
          quantity2: '',
          specs: '',
          unit_id: '',
        })
        // 处理车牌号
        let carCode = this.data.arr_deliver.filter(
          (item) => this.form.deliver_id == item.id
        )[0]
        console.log(carCode)
        if (carCode) {
          this.form.deliver_name =
            this.form.deliver_name + ' ' + carCode.depot_carno
        }
        // style样式选择
        // if (this.form.prize_amount == '' && this.form.cash_amount == '') {
        //   console.log('1样式')
        //   this.style = 1
        // } else if (
        //   this.form.prize_amount != '' &&
        //   this.form.cash_amount == ''
        // ) {
        //   console.log('兑奖样式')
        //   this.style = 2
        // } else if (
        //   this.form.cash_amount != '' &&
        //   this.form.prize_amount == ''
        // ) {
        //   console.log('兑付样式')
        //   this.style = 3
        // }
        // style样式选择
        if (
          this.form.sale_type == '1' ||
          this.form.sale_type == '2' ||
          this.form.sale_type == '3'
        ) {
          console.log('1样式')
          this.style = 1
        } else if (this.form.sale_type == '5' || this.form.sale_type == '6') {
          console.log('兑奖样式')
          this.style = 2
        } else if (this.form.sale_type == '4') {
          console.log('兑付样式')
          this.style = 3
        } else {
          this.style = 1
        }
        if (data.order_info.fun_ids == '') {
          this.form.fun_ids = []
        } else {
          this.form.fun_ids = data.order_info.fun_ids.split(',')
        }
        this.loading = false
      },
      showDialog() {
        this.isshowDialog = true
      },
      close() {
        this.isshowDialog = false
      },
      utilsF() {
        utils(this.middleTableData, this, 1)
      },
      yhInputF(val) {
        yhInput(val, this)
      },
      yhhInputF(val) {
        yhhInput(val, this)
      },
      // 表格内单位切换
      unitChange(e, row) {
        console.log(e, row)
        let price = ''
        if (
          row.goods_type == 6 ||
          row.goods_type == 10 ||
          row.goods_type == 11 ||
          row.goods_type == 13 ||
          row.goods_type == 14
        ) {
          price = row.arr_unit.filter((item) => item.id == e)[0].return_price
        } else {
          price = row.arr_unit.filter((item) => item.id == e)[0].sell_price
        }
        row.goods_price = price
      },
      // 商品搜索下拉
      async fetchData(ky = '') {
        let { data, code, msg } = await getInputList({
          pageNo: 1,
          pageSize: -1,
          keyword: ky,
          depot_id: this.form.depot_id,
        })
        console.log(data)
        if (code == 200) {
          return data
        } else {
          this.$message.error(msg)
        }
      },
      // 表格内商品名称输入框搜索
      querySearch2(queryString, cb) {
        if (queryString == '') {
          cb([])
        } else {
          this.fetchData(queryString).then((val) => {
            console.log(val)
            cb(val)
          })
        }
      },
      copyRow(index, row) {
        let rowS = JSON.parse(JSON.stringify(row))
        let rowa = Object.assign(rowS, { id: 0, edit: true })
        this.middleTableData.splice(index + 1, 0, rowa)
        // this.middleTableData.push(rowa)
        console.log(rowa)
      },
      deleteRow(index, row) {
        if (index == this.middleTableData.length - 1) {
          // alert('不能删除最后一行')
          this.$message.error('不能删除最后一行')
          return
        }
        this.middleTableData.splice(index, 1)
        this.utilsF()
      },
      addRows(val) {
        console.log('添加rows')
        console.log(val)
        if (val.length !== 0) {
          val.forEach((item) => {
            item.id = ''
            item.arr_unit.forEach((unit) => {
              if (unit.is_default == 2) {
                item.unit_id = unit.id
              }
            })
            this.middleTableData.splice(
              this.middleTableData.length - 1,
              0,
              item
            )
          })
          this.$message.success('添加成功')
        }
      },
      handleTypeChange(v, row) {
        console.log('v', v, row)
        let unitId = row.arr_unit.filter((i) => i.id == row.unit_id)
        if (v == 6 || v == 10 || v == 11 || v == 13 || v == 14) {
          if (unitId.length > 0) {
            row.goods_price = unitId[0].return_price
          }
        } else {
          row.goods_price = unitId[0].sell_price
        }
      },
      // 计算金额数
      moneyN(item, index) {
        let num = 0
        if (Number(item.quantity) && Number(item.goods_price)) {
          num = Number(item.quantity) * Number(item.goods_price)
        }
        this.middleTableData[index].goods_money = num.toFixed(2)
      },
      handleSelect($event, row, index) {
        console.log($event, row, index)
        Object.assign(row, $event, { goods_type: 1, unit_id: '' })
        row.id = ''
        // this.middleTableData[index] = $event
        // this.middleTableData[index].unit_id = ''
        // this.middleTableData[index].id = 0
        // this.middleTableData[index].goods_type = 1
        this.middleTableData[index].edit = true

        if (row.arr_unit) {
          console.log(this.middleTableData[index])
          row.arr_unit.forEach((unit) => {
            if (unit.is_default == 2) {
              row.unit_id = unit.id
            }
          })
        }
        console.log(this.middleTableData)
        this.addRow(index, row)
      },
      showSun() {
        this.$refs.arrivalSun.showDialog = true
      },
      // 添加行
      addRow(index, row, $event) {
        if (this.middleTableData.length == index + 1) {
          this.middleTableData.push({
            activity_code: '',
            activity_id: 0,
            activity_type: 0,
            balance_count: 0,
            depot_stock_num: 0,
            edit: true,
            goods_child_id: null,
            goods_money: '',
            goods_name: '',
            goods_price: '',
            goods_production_date: '',
            goods_type: '',
            info: '',
            money: '',
            quantity: '',
            quantity2: '',
            specs: '',
            unit_id: '',
          })
        }
      },
      // iconClick(index) {
      //   console.log(index)
      //   if (!this.form.depot_id) {
      //     this.$message.error('请选择出货仓库')
      //     return false
      //   } else {
      //     this.$refs['tableInputWindow'].showDialog = true
      //     this.$refs['tableInputWindow'].depotId = this.form.depot_id
      //   }
      //   // this.tableInputWindow = true
      // },
      // 派单到货点击
      handleSendOrder(val) {
        // if(this.middleTableData[this.middleTableData.length-1].goods_name == ''){
        try {
          this.middleTableData.forEach((item, index) => {
            if (
              item.goods_name == '' &&
              index !== this.middleTableData.length - 1
            ) {
              this.$message.error('请输入商品名称')
              throw new Error('请输入商品名称')
            }
          })
        } catch (e) {
          if (e.message == '请输入商品名称') {
            throw e
          }
        }
        // }
        let goods_data = []
        this.middleTableData.forEach((item, index) => {
          if (!item.id) {
            item.id = 0
          }
          if (
            index != this.middleTableData.length - 1 &&
            item.goods_name !== ''
          ) {
            goods_data.push({
              id: item.id,
              goods_type: item.goods_type,
              goods_id: item.goods_id,
              goods_name: item.goods_name,
              unit_id: item.unit_id,
              goods_price: item.goods_price,
              activity_money: item.activity_money,
              quantity: item.quantity,
              info: item.info,
              activity_id: item.activity_id,
              activity_count: item.activity_count,
              activity_type: item.activity_type,
              activity_code: item.activity_code,
            })
          }
        })
        console.log(goods_data)
        if (this.data.arr_collect.length == 0) {
          this.data.arr_collect = ''
        }
        let funids = JSON.parse(JSON.stringify(this.form.fun_ids))
        goods_data = JSON.stringify(goods_data)
        let data = {
          id: this.id,
          pay_data: JSON.stringify(val),
          goods_data,
          support_amount: this.form.support_amount,
          fun_ids: funids.join(','),
          discounted_amount: this.form.discounted_amount,
          new_refund_type: this.form.refund_type,
          remark: this.form.remark,
        }
        console.log(data)
        sendOrderArrival(data).then((res) => {
          this.isshowDialog = false
          setTimeout(() => {
            this.$emit('refresh')
          }, 800)
          this.$refs.arrivalSun.showDialog = false
          this.showDialog = false
          this.$message.success('派单到货成功')
        })
      },
      changeKouwei(val, row) {
        console.log('改变口味', val, row)
        row.goods_child_id = val.id
        row.unit_code = val.small_unit_code
        row.goods_name = val.goods_name + ` (${val.attr})`
      },
    },
  }
</script>

<style lang="scss" scoped>
  .setFilter {
    top: 40px;
    left: 7px;
  }
</style>
