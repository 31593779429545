var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        modal: false,
        title: "到货结算",
        visible: _vm.showDialog,
        width: "500px",
        top: "30vh",
        "close-on-click-modal": false,
        "destroy-on-close": "",
        center: "",
      },
      on: {
        "update:visible": function ($event) {
          _vm.showDialog = $event
        },
      },
    },
    [
      _c(
        "div",
        { staticClass: "orderTest-container" },
        [
          _c(
            "el-form",
            {
              ref: "form",
              attrs: { model: _vm.form, inline: "", "label-width": "110px" },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "订单应收款" } },
                [
                  _c("el-input", {
                    staticStyle: { width: "120px" },
                    attrs: { value: _vm.data.a, disabled: "" },
                  }),
                ],
                1
              ),
              _c("div"),
              _c(
                "el-form-item",
                { attrs: { label: "销售已收款" } },
                [
                  _c("el-input", {
                    staticStyle: { width: "120px" },
                    attrs: { value: _vm.data.b, disabled: "" },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "派送收款" } },
                _vm._l(_vm.list, function (item, index) {
                  return _c(
                    "div",
                    { key: index },
                    [
                      _c("el-input", {
                        staticStyle: { width: "120px" },
                        attrs: { disabled: "" },
                        model: {
                          value: item.amount,
                          callback: function ($$v) {
                            _vm.$set(item, "amount", $$v)
                          },
                          expression: "item.amount",
                        },
                      }),
                      _c(
                        "el-select",
                        {
                          staticStyle: {
                            width: "90px",
                            margin: "0 0 10px 10px",
                          },
                          model: {
                            value: item.pay_type,
                            callback: function ($$v) {
                              _vm.$set(item, "pay_type", $$v)
                            },
                            expression: "item.pay_type",
                          },
                        },
                        _vm._l(_vm.arr_pay_type, function (item_1) {
                          return _c("el-option", {
                            key: item_1.id,
                            attrs: { label: item_1.name, value: item_1.id },
                          })
                        }),
                        1
                      ),
                      index == 0
                        ? _c("i", {
                            staticClass: "el-icon-circle-plus addbtn",
                            on: { click: _vm.addInput },
                          })
                        : _vm._e(),
                    ],
                    1
                  )
                }),
                0
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          staticStyle: { "margin-top": "5px", "text-align": "right" },
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.save } },
            [_vm._v("确定")]
          ),
          _c("el-button", { on: { click: _vm.close } }, [_vm._v("取 消")]),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }