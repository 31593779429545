<template>
  <el-dialog
    :modal="false"
    :close-on-click-modal="false"
    title="派单"
    top="5vh"
    :visible.sync="showDialog"
    width="1380px"
    @close="close"
  >
    <div v-loading="loading" class="sendOrder">
      <el-alert :closable="false" :title="titleTip" type="success">
        {{ data.order_info && data.order_info.cancle_str }}
      </el-alert>
      <el-form
        ref="form"
        :model="form"
        label-width="80px"
        :inline="true"
        :rules="rules"
      >
        <el-form-item label="购货方" prop="cust_name">
          <el-input v-model.trim="form.cust_name" disabled></el-input>
        </el-form-item>
        <el-form-item label="联系人" prop="lxr">
          <el-input v-model.trim="form.lxr" disabled></el-input>
        </el-form-item>
        <el-form-item label="地址" prop="address" class="dz" style="width: 50%">
          <el-input
            v-model.trim="form.address"
            disabled
            style="width: 350px"
          ></el-input>
        </el-form-item>

        <el-form-item label="仓库" prop="depot_name">
          <el-input v-model.trim="form.depot_name" disabled></el-input>

          <!-- <el-select v-model="form.chck" placeholder="出货仓库">
            <el-option
              v-for="item in chckList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select> -->
        </el-form-item>

        <el-form-item label="业务员" prop="staff_name">
          <el-input v-model.trim="form.staff_name" disabled></el-input>
        </el-form-item>

        <el-form-item label="送货人" prop="deliver_id">
          <el-select
            v-model="form.deliver_id"
            placeholder="送货人"
            @change="chooseDeliver"
          >
            <el-option
              v-for="item in data.arr_deliver"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
          <span style="display: inline-block; width: 170px; padding-left: 15px">
            {{ carCode }}
          </span>
        </el-form-item>
        <el-form-item v-if="followuser.length != 0" label="随车人">
          <el-select
            v-model="form.follow_users"
            placeholder="随车人员"
            clearable
            multiple
          >
            <el-option
              v-for="item_ in followuser"
              :key="item_.id"
              :label="item_.user_name"
              :value="item_.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="调拨单" prop="allot_code">
          <el-input v-model.trim="form.allot_code" disabled></el-input>
        </el-form-item>
        <el-form-item label="派单时间" prop="delivery_time">
          <el-date-picker
            v-model="form.delivery_time"
            type="datetime"
            value-format="yyyy-MM-dd HH:mm:ss"
            format="yyyy-MM-dd HH:mm:ss"
            placeholder="选择日期时间"
          ></el-date-picker>
        </el-form-item>
      </el-form>
      <el-table
        ref="tableSort"
        v-loading="listLoading"
        stripe
        :data="list"
        style="margin-bottom: 15px"
      >
        <el-table-column
          align="center"
          type="selection"
          width="55"
        ></el-table-column>
        <el-table-column
          v-for="(item, index) in finallyColumns"
          :key="index"
          align="center"
          :label="item.label"
          :sortable="item.sortable"
          width=""
        >
          <template #default="{ $index, row }">
            <span v-if="item.label === '序号'">{{ $index + 1 }}</span>
            <div v-else-if="item.label === '类型'">
              <el-select v-model="row.goods_type" disabled style="width: 80px">
                <el-option
                  v-for="item2 in row.arr_goods_type"
                  :key="item2.id"
                  :label="item2.name"
                  :value="item2.id"
                ></el-option>
              </el-select>
            </div>
            <span v-else>{{ row[item.prop] }}</span>
          </template>
        </el-table-column>

        <!-- <el-table-column
          align="center"
          label="操作"
          fixed="right"
          show-overflow-tooltip
          width="190"
        >
          <template #default="{ row }">
            <el-button type="text" @click="handleEdit(row)">编辑</el-button>
            <el-button type="text" @click="handleSendOrder(row)">
              派单
            </el-button>
            <el-button type="text" @click="handleDelete(row)">取消</el-button>
            <el-button type="text" @click="handlePrint(row)">打印</el-button>
          </template>
        </el-table-column> -->
      </el-table>
      <!-- 底部文字 -->
      <el-form ref="form2" :model="form" label-width="120px" :inline="true">
        <div v-if="style == 1">
          <el-form-item label="备注" prop="remark" style="width: 100%">
            <el-input v-model="form.remark" style="width: 530px"></el-input>
          </el-form-item>
          <el-form-item
            label="订购总额"
            prop="total_amount"
            style="width: 100%; font-weight: 600"
          >
            <el-input v-model.trim="form.total_amount" disabled></el-input>
          </el-form-item>
          <!-- <el-form-item label="销售金额" prop="xsje">
          <el-input v-model.trim="form.xsje" disabled></el-input>
        </el-form-item>
        <el-form-item label="使用预存款" prop="syyck">
          <el-input v-model.trim="form.syyck" disabled></el-input>
        </el-form-item>
        <el-form-item label="优惠后" prop="yhh">
          <el-input v-model.trim="form.yhh" disabled></el-input>
        </el-form-item>

        <el-form-item label="还货金额" prop="hhje">
          <el-input v-model.trim="form.hhje" disabled></el-input>
        </el-form-item>
        <el-form-item label="退货退款" prop="thtk">
          <el-input v-model.trim="form.thtk" disabled></el-input>
        </el-form-item>
        <el-form-item label="退货金额" prop="thje">
          <el-input v-model.trim="form.thje" disabled></el-input>
        </el-form-item>
        <el-form-item label="应收款" prop="ysk">
          <el-input v-model.trim="form.ysk" disabled></el-input>
        </el-form-item>
        <el-form-item label="结算方式" prop="jsfs">
          <el-input v-model.trim="form.jsfs" disabled></el-input>
        </el-form-item>
        <el-form-item label="销售收款:">
          <el-input v-model.trim="form.xssk" disabled></el-input>
        </el-form-item> -->
          <el-form-item label="销售金额" prop="sale_amount">
            <el-input v-model.trim="form.sale_amount" disabled></el-input>
          </el-form-item>
          <el-form-item label="使用预存款" prop="deposit_amount">
            <el-input v-model.trim="form.deposit_amount" disabled></el-input>
          </el-form-item>

          <el-form-item label="还货金额" prop="return_amount">
            <el-input v-model.trim="form.return_amount" disabled></el-input>
          </el-form-item>

          <el-form-item label="优惠后" prop="discounted_amount">
            <el-input v-model.trim="form.discounted_amount" disabled></el-input>
          </el-form-item>
          <el-form-item label="退货金额" prop="refund_goods_amount">
            <el-input
              v-model.trim="form.refund_goods_amount"
              disabled
            ></el-input>
          </el-form-item>
          <!-- <el-form-item label="退货金额" prop="thje">
          <el-input v-model.trim="form.thje" disabled></el-input>
        </el-form-item> -->
          <el-form-item label="退货方式" prop="refund_type_text">
            <el-input v-model="form.refund_type_text" disabled></el-input>
            <!-- <el-select
            v-model="form.thfs"
            placeholder="退货方式"
            style="width: 200px"
          >
            <el-option
              v-for="item in thfsList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select> -->
          </el-form-item>
          <el-form-item
            label="退货退款"
            prop="refund_amount"
            style="width: 30%"
          >
            <el-input v-model.trim="form.refund_amount" disabled></el-input>
          </el-form-item>
          <el-form-item label="费用抵扣" prop="support_amount">
            <el-input v-model.trim="form.support_amount" disabled></el-input>
          </el-form-item>
          <el-form-item label="应收款" prop="receiv_money">
            <el-input v-model.trim="form.receiv_money" disabled></el-input>
          </el-form-item>
          <el-form-item
            label="结算方式"
            prop="account_type_text"
            style="width: 60%"
          >
            <el-input v-model="form.account_type_text" disabled></el-input>
          </el-form-item>
        </div>
        <div v-else-if="style == 2">
          <el-form-item label="兑奖现金:" prop="prize_amount">
            <el-input v-model.trim="form.prize_amount" disabled></el-input>
          </el-form-item>
        </div>
        <div v-else-if="style == 3">
          <el-form-item label="兑付现金:" prop="cash_amount">
            <el-input v-model.trim="form.cash_amount" disabled></el-input>
          </el-form-item>
        </div>
      </el-form>
      <!-- 底部按钮 -->
      <div
        v-if="btnShow"
        slot="footer"
        class="dialog-footer"
        style="text-align: right"
      >
        <template
          v-if="btnShow.pre_process_door == 0 && btnShow.distribute_only == 0"
        >
          <el-button type="warning" @click="save(1)">
            派单并生成调拨单
          </el-button>
          <el-button type="primary" @click="save()">派单</el-button>
        </template>
        <template
          v-else-if="
            btnShow.pre_process_door == 0 && btnShow.distribute_only == 1
          "
        >
          <el-button type="warning" @click="save(1)">
            派单并生成调拨单
          </el-button>
        </template>
        <template v-if="btnShow.pre_process_door == 1">
          <el-button type="primary" @click="save()">派单</el-button>
        </template>
        <el-button @click="close">取 消</el-button>
      </div>
    </div>
  </el-dialog>
</template>

<script>
  import { getOrderDetail, sendOrder } from '@/api/advanceOrder'
  import { postAction } from '@/api/Employee'
  export default {
    name: 'SendOrder',
    props: {
      btnShow: {
        type: [Boolean, Object],
        default: () => false,
      },
    },
    data() {
      return {
        id: 0,
        style: 1,
        data: {},
        // btnShow: 0,
        loading: true,
        listLoading: false,
        showDialog: false,
        //随车人
        followuser: [],
        // 单号
        orderCode: '',
        orderStatus: '状态',
        // 下单时间
        orderTime: '',
        form: {
          delivery_time: '',
        },
        carCode: '',
        chckList: [
          {
            label: '总仓',
            value: '总仓',
          },
          {
            label: 'A仓',
            value: 'A仓',
          },
          {
            label: 'B仓',
            value: 'B仓',
          },
        ],
        shrList: [
          {
            label: '张司机',
            value: '张司机',
          },
          {
            label: '李司机',
            value: '李司机',
          },
          {
            label: 'XXX',
            value: 'XXX',
          },
        ],
        // 表格
        list: [],
        thfsList: [
          {
            lable: '退现金',
            value: '退现金',
          },
          {
            lable: '转存货',
            value: '转存货',
          },
          {
            lable: '抵欠款',
            value: '抵欠款',
          },
        ],
        checkList: [
          '序号',
          '类型',
          '商品名称',
          '规格',
          '条码',
          '单位',
          '单价',
          '订购数量',
          '金额',
          '备注',
        ],
        columns: [
          {
            order: 1,
            label: '序号',
            width: '100',
            prop: '',
            sortable: false,
          },
          {
            order: 2,
            label: '类型',
            width: '110',
            prop: 'goods_type',
            sortable: false,
          },
          {
            order: 3,
            label: '商品名称',
            width: 'auto',
            prop: 'goods_name_print',
            sortable: false,
          },
          {
            order: 3.1,
            label: '规格',
            width: 'auto',
            prop: 'specs',
            sortable: false,
          },
          {
            order: 3.2,
            label: '条码',
            width: '110px',
            prop: 'unit_code',
            sortable: false,
          },
          {
            order: 4,
            label: '单位',
            width: '110', //'120',
            prop: 'unit_name',
            sortable: false,
          },
          {
            order: 5,
            label: '单价',
            width: '130',
            prop: 'goods_price',
            sortable: false,
          },
          {
            order: 6,
            label: '订购数量',
            width: '130',
            prop: 'pre_quantity',
            sortable: false,
          },
          {
            order: 7,
            label: '金额',
            width: '130',
            prop: 'goods_money',
            sortable: false,
          },
          {
            order: 8,
            label: '备注',
            width: 'auto',
            prop: 'info',
            sortable: false,
          },
        ],
        rules: {
          deliver_id: [
            {
              required: true,
              trigger: ['blur', 'change'],
              message: '请选择送货人',
            },
          ],
        },
      }
    },
    computed: {
      titleTip() {
        return (
          '单号:' +
          this.orderCode +
          ' 下单时间： ' +
          this.orderTime +
          ' 原单: ' +
          this.form.old_code +
          ' 修改人: ' +
          this.form.edit_name +
          ' 修改时间: ' +
          this.form.edit_time
        )
      },
      finallyColumns() {
        let finallyArray = []
        this.checkList.forEach((checkItem) => {
          finallyArray.push(
            this.columns.filter((item) => item.label === checkItem)[0]
          )
        })
        console.log(finallyArray)
        return _.sortBy(finallyArray, (item) => item.order)
      },
    },
    watch: {
      showDialog(val) {
        if (val) {
          this.fetchData()
        } else {
          this.carCode = ''
          this.id = 0
        }
      },
    },
    created() {
      // test().then((res) => {
      //   console.log(res)
      // })
      this.handlerfollowuserlist()
    },
    methods: {
      async handlerfollowuserlist() {
        return await postAction('saleAdmin/order-pre/option', {}).then(
          (res) => {
            this.followuser = res.data.order_follow_users
          }
        )
      },
      async fetchData() {
        this.loading = true
        let { data, msg, code } = await getOrderDetail({ id: this.id })
        if (code == 200) {
          console.log(data)
          this.data = data
          this.form = data.order_info
          this.orderStatus = data.order_info.check_status_text
          if (this.form.follow_users != '') {
            this.form.follow_users = data.order_info.follow_users.split(',')
          } else {
            this.form.follow_users = []
          }
          this.orderCode = data.order_info.bill_code
          this.orderTime = data.order_info.sale_time

          if (this.form.deliver_id == 0) {
            this.form.deliver_id = ''
          }
          // 处理联系人
          this.form.lxr = this.form.boss + ' ' + this.form.mobile
          this.list = data.order_detail_info
          this.thfsList = data.arr_refund_type
          // style样式选择
          // if (this.form.prize_amount == '' && this.form.cash_amount == '') {
          //   console.log('1样式')
          //   this.style = 1
          // } else if (
          //   this.form.prize_amount != '' &&
          //   this.form.cash_amount == ''
          // ) {
          //   console.log('兑奖样式')
          //   this.style = 2
          // } else if (
          //   this.form.cash_amount != '' &&
          //   this.form.prize_amount == ''
          // ) {
          //   console.log('兑付样式')
          //   this.style = 3
          // }
          // style样式选择
          if (
            this.form.sale_type == '1' ||
            this.form.sale_type == '2' ||
            this.form.sale_type == '3'
          ) {
            console.log('1样式')
            this.style = 1
          } else if (this.form.sale_type == '5' || this.form.sale_type == '6') {
            console.log('兑奖样式')
            this.style = 2
          } else if (this.form.sale_type == '4') {
            console.log('兑付样式')
            this.style = 3
          } else {
            this.style = 1
          }
        }
        this.loading = false
      },
      close() {
        this.showDialog = false
      },
      save(is_allot = 0) {
        const this_ = this
        let followUsers = null
        if (
          this.form.follow_users == null ||
          this.form.follow_users == undefined
        ) {
          followUsers = []
        } else {
          followUsers = JSON.parse(JSON.stringify(this.form.follow_users))
        }
        this.checkForm(function () {
          let data = {
            id: this_.id,
            delivery_time: this_.form.delivery_time,
            deliver_id: this_.form.deliver_id,
            follow_users: followUsers.join(','),
            is_allot,
          }
          console.log(data)
          if (is_allot != 1) {
            // 仅派单
            console.log('仅派单')
            sendOrder(data).then((res) => {
              if (res.code == 200) {
                setTimeout(() => {
                  this_.$message.success('派单成功')
                  this_.showDialog = false
                  this_.$emit('refresh')
                }, 800)
              }
            })
          } else {
            console.log('派单并调拨')
            sendOrder(data).then((res) => {
              if (res.code == 200) {
                setTimeout(() => {
                  this_.$message.success('派单调拨成功')
                  this_.showDialog = false
                  this_.$emit('refresh')
                }, 800)
              }
            })
          }
        })
      },
      chooseDeliver(val) {
        console.log(val)
        if (val) {
          this.carCode = this.data.arr_deliver.filter(
            (item) => item.id == val
          )[0].depot_carno
        } else {
          this.carCode = ''
        }
      },
      checkForm(cb) {
        this.$refs.form.validate((v) => {
          if (v) {
            cb()
          } else {
            return false
          }
        })
      },
    },
  }
</script>

<style lang="scss" scoped></style>
