<template>
  <el-dialog
    :modal="false"
    :close-on-click-modal="false"
    title="订单汇总"
    :visible.sync="dialogFormVisible"
    width="860px"
    @close="close"
  >
    <div
      v-for="(list, index) in sumlist"
      :key="index"
      style="border-bottom: #2e2f34 1px dashed"
    >
      <h3>{{ list.depot_name }}</h3>
      <el-table :data="list.order_list" border style="width: 100%">
        <el-table-column type="index"></el-table-column>
        <el-table-column
          prop="bill_code"
          label="单号"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="cust_name"
          label="客户名称"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="cust_address"
          label="地址"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="cust_boss"
          label="联系人"
          align="center"
        ></el-table-column>
      </el-table>
      <el-table :data="list.goods" border style="width: 100%">
        <el-table-column type="index"></el-table-column>
        <el-table-column
          prop="goods_name"
          label="商品名称"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="specs"
          label="规格"
          align="center"
        ></el-table-column>
        <el-table-column
          align="center"
          prop="sale_num"
          label="销售"
          width="160"
        ></el-table-column>
        <el-table-column
          align="center"
          prop="give_num"
          label="赠送"
          width="160"
        ></el-table-column>
        <el-table-column
          align="center"
          prop="sum_num"
          label="合计"
          width="160"
        ></el-table-column>
      </el-table>
      <el-button
        type="warning"
        style="margin: 10px 0px"
        @click="handlerdayin(list.depot_id)"
      >
        打印
      </el-button>
    </div>
    <ruilang-display-dialog
      ref="RuilangDisplayDialog"
      bill_type="LHZ"
      :data_id="1"
      :summary-params="dataform"
      :type="1"
    ></ruilang-display-dialog>
  </el-dialog>
</template>

<script>
  import { postAction } from '@/api/Employee'
  import RuilangDisplayDialog from '@/baseComponents/ruiLangDesign/ruilangDisplayDialog'
  export default {
    name: 'Index',
    components: {
      RuilangDisplayDialog,
    },
    data() {
      return {
        dialogFormVisible: false,
        form: {
          order_ids: '',
          depot_id: '',
        },
        sumlist: [
          {
            depot_id: '',
            depot_name: '',
            order_list: [],
            goods: [],
            goods_total: [],
          },
        ],
        data_id: '',
      }
    },
    computed: {
      dataform() {
        return this.form
      },
    },
    methods: {
      close() {
        this.dialogFormVisible = false
      },
      handlerinfo() {
        postAction('/saleAdmin/order-pre/batch-summary', this.form).then(
          (res) => {
            console.log(res)
            this.sumlist = res.data
          }
        )
      },
      handlerdayin(id) {
        this.form.depot_id = id
        // this.$refs['RuilangDisplayDialog'].batch = true
        this.$refs['RuilangDisplayDialog'].dialogFormVisible = true
      },
    },
  }
</script>

<style scoped></style>
