var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        modal: false,
        "close-on-click-modal": false,
        title: "汇总派单",
        visible: _vm.dialogFormVisible,
        width: "1160px",
        fullscreen: "",
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogFormVisible = $event
        },
        close: _vm.close,
      },
    },
    [
      _c("div", { staticClass: "wrapper" }, [
        _c(
          "div",
          { staticClass: "left" },
          [
            _c("h3", [_vm._v("选单" + _vm._s(_vm.showBtn))]),
            _c(
              "el-form",
              {
                ref: "form",
                attrs: { model: _vm.form, "label-width": "80px" },
              },
              [
                _c(
                  "div",
                  { staticClass: "topInput" },
                  [
                    _c(
                      "el-select",
                      {
                        attrs: { placeholder: "选择出货仓库", clearable: "" },
                        model: {
                          value: _vm.form.depot_id,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "depot_id", $$v)
                          },
                          expression: "form.depot_id",
                        },
                      },
                      _vm._l(_vm.chckList, function (item) {
                        return _c("el-option", {
                          key: item.id,
                          attrs: { label: item.name, value: item.id },
                        })
                      }),
                      1
                    ),
                    _c(
                      "el-select",
                      {
                        attrs: {
                          placeholder: "选择业务员选单",
                          multiple: "",
                          clearable: "",
                        },
                        model: {
                          value: _vm.form.staff_ids,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "staff_ids", $$v)
                          },
                          expression: "form.staff_ids",
                        },
                      },
                      _vm._l(_vm.ywyList, function (item) {
                        return _c("el-option", {
                          key: item.id,
                          attrs: { label: item.name, value: item.id },
                        })
                      }),
                      1
                    ),
                    _c(
                      "el-select",
                      {
                        staticStyle: { width: "140px" },
                        attrs: { clearable: "", placeholder: "请选择路线" },
                        model: {
                          value: _vm.form.line_id,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "line_id", $$v)
                          },
                          expression: "form.line_id",
                        },
                      },
                      _vm._l(_vm.lineSelect, function (i, idx) {
                        return _c("el-option", {
                          key: idx,
                          attrs: { value: i.line_id, label: i.line_name },
                        })
                      }),
                      1
                    ),
                    _c("el-date-picker", {
                      staticStyle: { width: "200px" },
                      attrs: {
                        type: "daterange",
                        "range-separator": "-",
                        "value-format": "yyyy-MM-dd",
                        "start-placeholder": "开始日期",
                        "end-placeholder": "结束日期",
                      },
                      model: {
                        value: _vm.time,
                        callback: function ($$v) {
                          _vm.time = $$v
                        },
                        expression: "time",
                      },
                    }),
                    _c(
                      "el-button",
                      {
                        attrs: { type: "primary" },
                        on: { click: _vm.handleQuery },
                      },
                      [_vm._v("查询")]
                    ),
                    _c("el-button", { on: { click: _vm.check } }, [
                      _vm._v("勾选"),
                    ]),
                    _c(
                      "el-button",
                      {
                        attrs: { type: "primary" },
                        on: { click: _vm.addTable },
                      },
                      [_vm._v("添加")]
                    ),
                  ],
                  1
                ),
              ]
            ),
            _c(
              "el-table",
              {
                directives: [
                  {
                    name: "loading",
                    rawName: "v-loading",
                    value: _vm.listLoading,
                    expression: "listLoading",
                  },
                ],
                ref: "leftTable",
                staticStyle: { "margin-top": "10px" },
                attrs: { stripe: "", data: _vm.leftTableData, height: "550px" },
                on: { "selection-change": _vm.setSelectRows },
              },
              [
                _c("el-table-column", {
                  attrs: { align: "center", type: "selection", width: "55" },
                }),
                _vm._l(_vm.finallyColumns, function (item, index) {
                  return _c("el-table-column", {
                    key: index,
                    attrs: {
                      align: "center",
                      label: item.label,
                      sortable: item.sortable,
                      width: "",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (ref) {
                            var row = ref.row
                            return [
                              _c(
                                "span",
                                {
                                  class:
                                    item.label == "单号" ? "underline" : "",
                                  on: {
                                    click: function ($event) {
                                      return _vm.handleCheckDetail(row)
                                    },
                                  },
                                },
                                [_vm._v(" " + _vm._s(row[item.prop]) + " ")]
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      true
                    ),
                  })
                }),
              ],
              2
            ),
          ],
          1
        ),
        _c("div", { staticClass: "middle" }, [
          _c("i", {
            staticClass: "el-icon-caret-right",
            on: { click: _vm.addTable },
          }),
        ]),
        _c(
          "div",
          {
            staticClass: "right",
            staticStyle: { height: "647px", overflow: "auto" },
          },
          [
            _c("h3", [_vm._v("派单")]),
            _vm.showRtable
              ? _c(
                  "div",
                  {
                    staticStyle: {
                      "margin-top": "70px",
                      color: "#606266",
                      "text-align": "center",
                    },
                  },
                  [_vm._v(" 暂无数据 ")]
                )
              : _vm._e(),
            _c("div", {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.ll,
                  expression: "ll",
                },
              ],
            }),
            _vm._l(_vm.rightTableData, function (item, index) {
              return _c(
                "div",
                {
                  key: index,
                  staticClass: "right-content topInfo",
                  staticStyle: { "margin-bottom": "15px" },
                },
                [
                  _c("div", { staticClass: "inp" }, [
                    _c("div", [_vm._v("出货仓库: " + _vm._s(item.depot_name))]),
                    _c(
                      "div",
                      [
                        _vm._v(" 派送司机: "),
                        _c(
                          "el-select",
                          {
                            staticStyle: { width: "130px" },
                            attrs: { placeholder: "选择司机", clearable: "" },
                            on: {
                              change: function ($event) {
                                return _vm.chooseDeliver(item, $event)
                              },
                            },
                            model: {
                              value: item.deliver,
                              callback: function ($$v) {
                                _vm.$set(item, "deliver", $$v)
                              },
                              expression: "item.deliver",
                            },
                          },
                          _vm._l(item.arr_deliver, function (item2) {
                            return _c("el-option", {
                              key: item2.id,
                              attrs: { label: item2.name, value: item2.id },
                            })
                          }),
                          1
                        ),
                      ],
                      1
                    ),
                    _c("div", { staticStyle: { width: "120px" } }, [
                      _vm._v("车辆: " + _vm._s(item.carCode)),
                    ]),
                  ]),
                  _c(
                    "div",
                    { staticClass: "table" },
                    [
                      _c(
                        "el-table",
                        { attrs: { stripe: "", data: item.goods } },
                        _vm._l(_vm.finallyColumns2, function (colItem, index3) {
                          return _c("el-table-column", {
                            key: index3,
                            attrs: {
                              align: "center",
                              label: colItem.label,
                              sortable: colItem.sortable,
                              width: colItem.width,
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function (ref) {
                                    var row = ref.row
                                    return [
                                      _c("span", [
                                        _vm._v(_vm._s(row[colItem.prop])),
                                      ]),
                                    ]
                                  },
                                },
                              ],
                              null,
                              true
                            ),
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ]
              )
            }),
          ],
          2
        ),
      ]),
      _vm.btnShow
        ? _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                { attrs: { type: "warning" }, on: { click: _vm.clearR } },
                [_vm._v("清空")]
              ),
              _vm.btnShow.pre_process_door == 0 &&
              _vm.btnShow.distribute_only == 0
                ? [
                    _c(
                      "el-button",
                      {
                        attrs: { type: "warning", loading: _vm.loadingBtn },
                        on: { click: _vm.saveAlloct },
                      },
                      [_vm._v(" 派单并生成调拨单 ")]
                    ),
                    _c(
                      "el-button",
                      { attrs: { type: "primary" }, on: { click: _vm.save } },
                      [_vm._v("派单")]
                    ),
                  ]
                : _vm.btnShow.pre_process_door == 0 &&
                  _vm.btnShow.distribute_only == 1
                ? [
                    _c(
                      "el-button",
                      {
                        attrs: { type: "warning", loading: _vm.loadingBtn },
                        on: { click: _vm.saveAlloct },
                      },
                      [_vm._v(" 派单并生成调拨单 ")]
                    ),
                  ]
                : _vm._e(),
              _vm.btnShow.pre_process_door == 1
                ? [
                    _c(
                      "el-button",
                      { attrs: { type: "primary" }, on: { click: _vm.save } },
                      [_vm._v("派单")]
                    ),
                  ]
                : _vm._e(),
              _c("el-button", { on: { click: _vm.close } }, [_vm._v("取 消")]),
            ],
            2
          )
        : _vm._e(),
      _c("advance-order-detail", { ref: "advanceOrderDetail" }),
      _c("sub-dialog", {
        ref: "subDialog",
        attrs: {
          datalist: _vm.subData,
          sumdata: _vm.sumdata,
          cancelid: _vm.allId,
        },
        on: {
          "update:cancelid": function ($event) {
            _vm.allId = $event
          },
          refresh: _vm.getLeftTableData,
          "refresh-index": _vm.fetchDataIndex,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }