<template>
  <!-- TODO[epic=name] 预订单 -->
  <div class="orderTest-container">
    <div class="topInput">
      <el-select
        v-model="queryForm.depot_id"
        :popper-class="'select-idx'"
        filterable
        clearable
        placeholder="选择出货仓库"
      >
        <el-option
          v-for="item in depotList"
          :key="item.id"
          :label="item.name"
          :value="item.id"
        ></el-option>
      </el-select>
      <el-select
        v-model="queryForm.staff_id"
        filterable
        clearable
        multiple
        :collapse-tags="true"
        :popper-class="'select-idx'"
        placeholder="选择业务员"
      >
        <el-option
          v-for="item in saleManList"
          :key="item.id"
          :label="item.name"
          :value="item.id"
        ></el-option>
      </el-select>
      <el-select
        v-model="queryForm.bill_status"
        filterable
        clearable
        :popper-class="'select-idx'"
        placeholder="选择订单状态"
      >
        <el-option
          v-for="item in bill_status"
          :key="item.id"
          :label="item.name"
          :value="item.id"
        ></el-option>
      </el-select>
      <el-select
        v-model="queryForm.order_type"
        filterable
        clearable
        :popper-class="'select-idx'"
        placeholder="调拨状态"
      >
        <el-option
          v-for="item in order_type"
          :key="item.id"
          :label="item.name"
          :value="item.id"
        ></el-option>
      </el-select>
      <el-select
        v-model="queryForm.time_type"
        filterable
        clearable
        :popper-class="'select-idx'"
        placeholder="下单时间"
      >
        <el-option
          v-for="item in orderTimeList"
          :key="item.id"
          :label="item.name"
          :value="item.id"
        ></el-option>
      </el-select>
      <el-tooltip
        class="item"
        effect="dark"
        content="默认显示6个月内的订单，可点击时间框修改起止时间，查询其他时段订单"
        placement="top-start"
      >
        <el-date-picker
          v-model="queryForm.time"
          :popper-class="'select-idx'"
          type="datetimerange"
          range-separator="-"
          value-format="yyyy-MM-dd HH:mm:ss"
          :default-time="['00:00:00', '23:59:59']"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
        ></el-date-picker>
      </el-tooltip>

      <el-select v-model="queryForm.line_id" clearable placeholder="请选择线路">
        <el-option
          v-for="(i, idx) in lineSelect"
          :key="idx"
          :value="i.id"
          :label="i.line_name"
        ></el-option>
      </el-select>
    </div>
    <!-- 更多筛选 -->
    <div class="more">
      <el-collapse v-model="activeNames">
        <el-collapse-item title="更多筛选条件" name="1">
          <el-cascader
            v-model="queryForm.cust_area_id"
            :options="areaList"
            placeholder="选择区域"
            :props="{
              checkStrictly: true,
              value: 'id',
              label: 'name',
              emitPath: false,
            }"
            clearable
            style="width: 140px; margin: 5px"
          ></el-cascader>
          <el-select
            v-model="queryForm.cust_channel_id"
            placeholder="选择渠道类型"
            :popper-class="'select-idx'"
            filterable
            clearable
            style="width: 140px; margin: 5px"
          >
            <el-option
              v-for="item in cust_channel"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
          <el-select
            v-model="queryForm.cust_class_id"
            placeholder="客户等级"
            :popper-class="'select-idx'"
            filterable
            clearable
            style="width: 140px; margin: 5px"
          >
            <el-option
              v-for="item in cust_class"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
          <el-select
            v-model="queryForm.deliver_id"
            placeholder="司机"
            filterable
            :popper-class="'select-idx'"
            clearable
            style="width: 140px; margin: 5px"
          >
            <el-option
              v-for="item in deliver"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
          <el-select
            v-model="queryForm.bill_type"
            placeholder="来源"
            :popper-class="'select-idx'"
            filterable
            clearable
            style="width: 140px; margin: 5px"
          >
            <el-option
              v-for="item in bill_type"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
          <span style="margin: 0 5px">生产日期:</span>
          <el-date-picker
            v-model="time2"
            style="width: 340px; margin: 5px"
            type="daterange"
            range-separator="-"
            value-format="yyyy-MM-dd"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
          ></el-date-picker>
          <el-select
            v-model="queryForm.pay_status"
            placeholder="是否结清"
            :popper-class="'select-idx'"
            clearable
            style="width: 140px; margin: 5px"
          >
            <el-option label="未结清" value="0"></el-option>
            <el-option label="已结清" value="1"></el-option>
            <el-option label="坏账" value="2"></el-option>
          </el-select>

          <el-select
            v-model="queryForm.has_sign"
            placeholder="客户签名"
            :popper-class="'select-idx'"
            filterable
            clearable
            style="width: 140px; margin: 5px"
          >
            <el-option
              v-for="item in hasSignSelect"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-collapse-item>
      </el-collapse>
    </div>
    <!-- 表格 -->
    <div class="table-wrap">
      <div class="table-container">
        <vab-query-form>
          <vab-query-form-top-panel>
            <el-form
              ref="form"
              :inline="true"
              :model="queryForm"
              label-width="80px"
              @submit.native.prevent
            >
              <el-form-item>
                <client-search
                  :popper-class="'select-idx selectDC'"
                  @select-id="selectId"
                  @select-val-input="custInput"
                ></client-search>
              </el-form-item>
              <el-form-item prop="brand">
                <brand-select
                  ref="brandSelect"
                  :duoxuan="true"
                  :tags="true"
                  style="width: 220px"
                  :popper-class="'select-idx'"
                  @brand-select-change="chooseBrand"
                ></brand-select>
              </el-form-item>
              <el-form-item label="" prop="">
                <el-input
                  v-model="queryForm.keyword_goods"
                  placeholder="商品名称"
                  clearable
                ></el-input>
              </el-form-item>
              <el-form-item label="" prop="">
                <el-input
                  v-model="queryForm.remark"
                  placeholder="单号或单据备注"
                  clearable
                ></el-input>
              </el-form-item>
              <el-form-item label="" prop="">
                <el-select
                  v-model="queryForm.account_type"
                  placeholder="结算方式"
                  :popper-class="'select-idx'"
                  filterable
                  clearable
                  style="width: 140px"
                >
                  <el-option
                    v-for="item in account_type"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id"
                  ></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="" prop="">
                <el-select
                  v-model="queryForm.sale_type"
                  placeholder="销售类型"
                  :popper-class="'select-idx'"
                  filterable
                  clearable
                  style="width: 140px"
                >
                  <el-option
                    v-for="item in sale_type"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id"
                  ></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="" prop="">
                <el-select
                  v-model="queryForm.print"
                  placeholder="打印状态"
                  :popper-class="'select-idx'"
                  filterable
                  clearable
                  style="width: 140px"
                >
                  <el-option
                    v-for="item in print"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id"
                  ></el-option>
                </el-select>
              </el-form-item>
              <el-form-item>
                <el-button
                  icon="el-icon-search"
                  native-type="submit"
                  type="primary"
                  @click="btnQuery"
                >
                  查询
                </el-button>
                <el-button type="warning" @click="btnSummaryOrder">
                  汇总派单
                </el-button>
                <el-button type="success" @click="handleBatchArrival">
                  批量到货
                </el-button>
                <el-button
                  type="primary"
                  style="margin-left: 40px"
                  @click="btnAddOrder"
                >
                  添加订单
                </el-button>
                <el-button
                  type="warning"
                  style="margin-left: 40px"
                  @click="handleAddDuijiang"
                >
                  添加兑奖
                </el-button>
                <el-button @click="btnExpotr">导出</el-button>
              </el-form-item>
            </el-form>
          </vab-query-form-top-panel>
          <!-- <vab-query-form-left-panel>
            <el-button icon="el-icon-plus" type="primary" @click="handleAdd">
              添加
            </el-button>
            <el-button
              icon="el-icon-delete"
              type="danger"
              @click="handleDelete"
            >
              删除
            </el-button>
          </vab-query-form-left-panel> -->
        </vab-query-form>
        <!-- <div class="setFilter">
          <el-popover popper-class="custom-table-checkbox" trigger="hover">
            <el-checkbox-group v-model="checkList">
              <el-checkbox
                v-for="(item, index) in columns"
                :key="index"
                :label="item.label"
              ></el-checkbox>
            </el-checkbox-group>
            <el-button slot="reference">
              <vab-remix-icon icon="settings-line" />
            </el-button>
          </el-popover>
        </div> -->
        <el-table
          ref="tableSort"
          v-loading="listLoading"
          stripe
          max-height="800"
          border
          show-summary
          :summary-method="getSummaries"
          :row-key="getRowKey"
          :data="list"
          @selection-change="setSelectRows"
        >
          <!-- 序号 -->
          <el-table-column align="center" type="index" width="50">
            <template slot="header">
              <el-popover popper-class="custom-table-checkbox" trigger="hover">
                <el-checkbox-group v-model="checkList">
                  <el-checkbox
                    v-for="(item, index) in columns"
                    :key="index"
                    :label="item.label"
                    @change="filterChcekboxChange"
                  ></el-checkbox>
                </el-checkbox-group>
                <el-button slot="reference" type="text">
                  <vab-remix-icon icon="settings-line" />
                </el-button>
              </el-popover>
            </template>
          </el-table-column>
          <!-- 选择框 -->
          <el-table-column
            align="center"
            :reserve-selection="true"
            type="selection"
            width="50"
          ></el-table-column>
          <el-table-column
            v-for="(item, index) in finallyColumns"
            :key="index"
            align="center"
            :label="item.label"
            :sortable="item.sortable"
            :width="item.width"
          >
            <template #default="{ row }">
              <div v-if="item.label == '状态'">
                <div v-if="row.bill_status == 4">已作废</div>
                <div v-else>
                  <div v-if="row.check_status == 1">待审核</div>
                  <div v-else>
                    {{ row.bill_status_text }}
                  </div>
                </div>
              </div>
              <div v-else-if="item.label == '单号'">
                <div class="under-line" @click="checkOrder(row)">
                  <p :class="/[-]/.test(row[item.prop]) ? 'red' : ''">
                    {{ row[item.prop] }}
                  </p>
                </div>
                <div class="btnwrap">
                  <el-tag v-for="tag in row.is_abnormal_type_text" :key="tag">
                    {{ tag }}
                  </el-tag>
                  <el-tag v-for="tag in row.sale_type_txt" :key="tag">
                    {{ tag }}
                  </el-tag>
                  <el-tag v-if="Number(row.discount_amount) != 0">优惠</el-tag>
                </div>
              </div>
              <div v-else>{{ row[item.prop] }}</div>
            </template>
          </el-table-column>

          <el-table-column
            fixed="right"
            align="center"
            label="操作"
            width="210"
          >
            <template #default="{ row }">
              <!--
                bill_status: 1
                bill_status_text: "待派单"

                bill_status: 2
                bill_status_text: "已派单"

                bill_status: 3
                bill_status_text: "已到货"

                bill_status: 4
                bill_status_text: "已作废"

                check_status: 1
                check_status_text: "待审核"

                check_status: 0
                check_status_text: "已审核"

                0未结清 1已结清pay_status
               -->
              <!-- <el-button
                v-if="row.sale_type_txt.length == 0 && (row.bill_status == 4 || row.bill_status == 3)"
                type="text"
                @click="handleCopy(row)"
              >
                复制
              </el-button> -->
              <template v-if="row.account_type == 3">
                <div v-if="row.pay_status == 0 || row.bill_status == 3">
                  <el-button type="text" @click="checkOrder(row)">
                    查看
                  </el-button>
                  <el-button type="text" @click="handlePrint(row)">
                    打印
                  </el-button>
                </div>
                <div v-else-if="row.pay_status == 1">
                  <!-- 已结清 -->
                  <div v-if="row.bill_status == 1">
                    <!-- 待派单 -->
                    <el-button type="text" @click="handleSendOrder(row)">
                      派单
                    </el-button>
                    <el-button type="text" @click="handlePrint(row)">
                      打印
                    </el-button>
                  </div>
                  <div v-if="row.bill_status == 2">
                    <!-- 已派单 -->
                    <el-button
                      slot="reference"
                      type="text"
                      @click="handleUnSendOrder(row)"
                    >
                      取消派单
                    </el-button>
                    <el-button type="text" @click="showdaohuo(row)">
                      到货
                    </el-button>
                    <el-button type="text" @click="handlePrint(row)">
                      打印
                    </el-button>
                  </div>
                </div>
              </template>
              <template v-else>
                <el-button
                  v-if="
                    row.check_status == 1 &&
                    (row.bill_status == 1 || row.bill_status == 2)
                  "
                  type="text"
                  @click="handleReview(row)"
                >
                  审核
                </el-button>
                <!-- 待审核 -->
                <template v-if="row.check_status == 1">
                  <el-button
                    v-if="row.bill_status == 1 || row.bill_status == 2"
                    type="text"
                    @click="handleEdit(row)"
                  >
                    编辑
                  </el-button>
                </template>
                <!-- 已审核 -->
                <template v-if="row.check_status == 0">
                  <el-button
                    v-if="row.bill_status == 1"
                    type="text"
                    @click="handleEdit(row)"
                  >
                    编辑
                  </el-button>
                </template>
                <el-button
                  v-if="row.bill_status == 3 || row.bill_status == 4"
                  type="text"
                  @click="checkOrder(row)"
                >
                  查看
                </el-button>
                <el-button
                  v-if="row.bill_status == 3 && authbutton[0].is_check == 1"
                  type="text"
                  @click="cancelArrival(row)"
                >
                  取消到货
                </el-button>
                <el-button
                  v-if="row.check_status == 0 && row.bill_status == 1"
                  type="text"
                  @click="handleSendOrder(row)"
                >
                  派单
                </el-button>
                <el-button
                  v-if="row.check_status == 0 && row.bill_status == 2"
                  slot="reference"
                  type="text"
                  @click="handleUnSendOrder(row)"
                >
                  取消派单
                </el-button>

                <el-button
                  v-if="row.check_status == 0 && row.bill_status == 2"
                  type="text"
                  @click="showdaohuo(row)"
                >
                  到货
                </el-button>
                <el-button
                  v-if="
                    (row.check_status == 1 &&
                      (row.bill_status == 1 || row.bill_status == 2)) ||
                    (row.check_status == 0 && row.bill_status == 1)
                  "
                  type="text"
                  @click="handleDelete(row)"
                >
                  作废
                </el-button>
                <el-button
                  v-if="row.check_status == 0"
                  type="text"
                  @click="handlePrint(row)"
                >
                  打印
                </el-button>
              </template>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination
          :current-page="queryForm.pageNo"
          :layout="layout"
          :page-size="queryForm.pageSize"
          :total="total"
          background
          @current-change="handleCurrentChange"
          @size-change="handleSizeChange"
        ></el-pagination>
        <!--        2023.5.22修改-->
        <!--        <el-button
          v-if="btnShow.pre_process_door != 1"
          type="success"
          @click="handleBatchArrival"
        >
          批量到货
        </el-button>-->

        <el-button @click="btnPrint">批量打印</el-button>
        <!-- <el-button style="float: right" @click="handleImport">导入</el-button> -->
        <el-button type="warning" @click="handlerSummary">批量汇总</el-button>
        <el-dialog
          title="提示"
          :visible.sync="showDialog2"
          append-to-body
          width="400px"
          top="20vh"
          :close-on-click-modal="false"
          :modal="true"
        >
          <div v-loading="loading" class="orderTest-container">
            <p>该订单已关联调拨单:{{ orderCode }}</p>
            <p>是否取消派单并同时撤销调拨？</p>
            <div style="margin: 0; text-align: right">
              <el-button size="mini" type="primary" @click="cancelOrderAnd">
                取消派单并撤销调拨
              </el-button>
              <el-button
                v-if="showSendBtn"
                size="mini"
                @click="onlyCancelOrder"
              >
                仅取消派单
              </el-button>
            </div>
          </div>
        </el-dialog>
        <!-- tpl -->
        <table-edit v-if="0" ref="edit" @fetch-data="fetchData" />
        <order-table-edit
          ref="orderTableEdit"
          :btn-show="btnShow"
          @refresh="fetchData"
        ></order-table-edit>
        <summary-order
          ref="summaryOrder"
          :btn-show="btnShow"
          @fetch-data="fetchData"
          @refresh="fetchData"
        ></summary-order>
        <add-order
          ref="addOrder"
          :btn-show="btnShow"
          @refresh="settimefetchData"
        ></add-order>
        <send-order
          ref="sendOrder"
          :btn-show="btnShow"
          @refresh="fetchData"
        ></send-order>
        <check-order ref="checkOrder"></check-order>
        <arrival ref="arrival" @refresh="fetchData"></arrival>
        <arrival-pay ref="arrivalPay" @refresh="fetchData"></arrival-pay>
        <review ref="review" @refresh="fetchData"></review>
      </div>
    </div>
    <!--    打印预览组件-->
    <ruilang-display-dialog
      ref="RuilangDisplayDialog"
      bill_type="XD"
      :data_id="data_id"
      :type="1"
    ></ruilang-display-dialog>
    <import-tpl ref="importTpl"></import-tpl>
    <dui-jiang ref="duiJiang" :sale-type="2" @refresh="fetchData"></dui-jiang>
    <ordersum ref="order" />
  </div>
</template>

<script>
  import _ from 'lodash'
  // import { doDelete } from '@/api/table'
  import { exportExcel } from '@/utils/exportExcel'
  // 组件
  import ordersum from './components/ordersum'
  import TableEdit from './components/TableEdit'
  import OrderTableEdit from './components/orderTableEdit'
  import SummaryOrder from './components/summaryOrder'
  import AddOrder from './components/addOrder'
  import SendOrder from './components/sendOrder'
  import CheckOrder from './components/checkOrder'
  import Arrival from './components/arrival'
  import ArrivalPay from './components/arrivalPay'
  import ClientSearch from '@/baseComponents/clientSearch'
  import { getSelect, saleCancelArrival } from '@/api/saleOrder'
  import ImportTpl from '@/views/index/components/importTpl.vue'
  import DuiJiang from '@/views/project/treasury/depotOperation/depotSale/components/duiJiang'
  import { clientLineList } from '@/api/ClientDetail' // 线路下拉

  import {
    getAdvanceOrderClientSelectList,
    getAdvanceOrderTableList,
    invalidOrder,
    unSendOrder,
    beforeUnSendOrder,
  } from '@/api/advanceOrder'
  import RuilangDisplayDialog from '@/baseComponents/ruiLangDesign/ruilangDisplayDialog'
  import { baseURL } from '@/config/default/setting.config'
  import Review from './components/review'
  import { day_n, day_nM, day_ns } from '@/utils/Time'
  import BrandSelect from '@/baseComponents/brandSelect'
  import { postAction } from '@/api/Employee'

  export default {
    name: 'AdvanceOrder',
    components: {
      ordersum,
      BrandSelect,
      RuilangDisplayDialog,
      TableEdit,
      OrderTableEdit,
      SummaryOrder,
      AddOrder,
      SendOrder,
      CheckOrder,
      Arrival,
      ArrivalPay,
      ClientSearch,
      Review,
      ImportTpl,
      DuiJiang,
    },
    data() {
      return {
        authbutton: [],
        lineSelect: [],
        totalAll: {}, //总计
        loading: false,
        time2: [],
        showDialog2: false,
        data_id: 0,
        showSendBtn: 0,
        restaurants: [],
        areaList: [],
        saleManList: [],
        depotList: [],
        orderStatusList: [],
        allotStatusList: [],
        orderTimeList: [],
        cust_channel: [],
        cust_class: [],
        deliver: [],
        bill_type: [],
        account_type: [],
        print: [],
        sale_type: [],
        bill_status: [], //状态
        order_type: [], //调拨状态
        depot: '',
        saleMan: '',
        orderStatus: '',
        allotStatus: '',
        searchValue: '',
        indexTableData: '',
        selectRes: [],
        // 筛选条件
        activeNames: ['0'],
        // 表格
        checkList: [
          '单号',
          '客户名称',
          '来源',
          '出货仓库',
          '业务员',
          '送货人',
          '订单金额',
          '应收金额',
          '实收金额',
          '状态',
          '下单时间',
          '备注',
          '打印数',
        ],
        columns: [
          {
            order: 2,
            label: '单号',
            width: '160',
            prop: 'bill_code',
            sortable: false,
          },
          {
            order: 3,
            label: '客户名称',
            width: '160',
            prop: 'cust_name',
            sortable: false,
          },
          {
            order: 4,
            label: '来源',
            width: '',
            prop: 'bill_type_text',
            sortable: false,
          },
          {
            order: 5,
            label: '出货仓库',
            width: '',
            prop: 'depot_name',
            sortable: false,
          },
          {
            order: 6,
            label: '业务员',
            width: '',
            prop: 'staff_name',
            sortable: false,
          },
          {
            order: 7,
            label: '送货人',
            width: '',
            prop: 'deliver_name',
            sortable: false,
          },
          {
            order: 8,
            label: '订单金额',
            width: '',
            prop: 'total_amount',
            sortable: false,
          },
          {
            order: 9,
            label: '应收金额',
            width: '',
            prop: 'receiv_money',
            sortable: false,
          },
          {
            order: 10,
            label: '实收金额',
            width: '',
            prop: 'pay_amount',
            sortable: false,
          },
          {
            order: 11,
            label: '状态',
            width: '',
            prop: 'bill_status_text',
            sortable: false,
          },
          {
            order: 12,
            label: '下单时间',
            width: '',
            prop: 'create_at',
            sortable: false,
          },
          {
            order: 12.5,
            label: '备注',
            width: '',
            prop: 'remark',
            sortable: false,
          },
          {
            order: 13,
            label: '打印数',
            width: '',
            prop: 'print_num',
            sortable: false,
          },
        ],
        hasSignSelect: [
          {
            id: 1,
            name: '有签名',
          },
          {
            id: 2,
            name: '无签名',
          },
        ],
        list: [],
        listLoading: true,
        currentRow: {},
        layout: 'total, sizes, prev, pager, next, jumper',
        total: 0,
        selectRows: '',
        queryForm: {
          line_id: '',
          create_at: '',
          order_type: '',
          orderTime: '',
          start_date: '',
          end_date: '',
          pageNo: 1,
          pageSize: 10,
          depot_id: '',
          staff_id: [],
          pay_status: '',
          bill_status: '',
          time_type: '',
          time: [],
          cust_channel_id: '',
          cust_class_id: '',
          cust_area_id: '',
          start_time: '',
          end_time: '',
          areaList: '',
          cust_channel: '',
          cust_class: '',
          deliver_id: '',
          bill_type: '',
          account_type: '',
          sale_type: '',
          print: '',
          remark: '',
          // 客户简拼
          keyword: '',
          cust_id: '',
        },
        showUnSendOrderDialog: false,
        orderCode: '',
        btnShow: false,
      }
    },
    computed: {
      finallyColumns() {
        let finallyArray = []
        this.checkList.forEach((checkItem) => {
          finallyArray.push(
            this.columns.filter((item) => item.label === checkItem)[0]
          )
        })
        return _.sortBy(finallyArray, (item) => Math.floor(item.order / 10))
      },
    },
    watch: {
      time2(val) {
        if (val) {
          this.queryForm.start_date = val[0]
          this.queryForm.end_date = val[1]
        } else {
          this.queryForm.start_date = ''
          this.queryForm.end_date = ''
        }
      },
    },
    activated() {
      this.fetchData()
    },
    async created() {
      this.initTime()
      console.log('客户下拉菜单数据获取12312312312')
      this.getTableWidth()
      // 客户下拉菜单数据获取
      this.loadingS = true
      getSelect().then((res) => {
        // this.selectRes = res
        if (res.code == 200) {
          let data = res.data
          this.btnShow = res.data
          console.log(data.distribute_only)
          // this.$refs.summaryOrder.showBtn = data.distribute_only
          // this.$refs.sendOrder.showBtn = data.distribute_only
          // this.showSendBtn = data.distribute_only
          this.depotList = data.depot
          this.saleManList = data.staff // 业务员
          this.orderStatusList = data.pay_status // 是否结清
          this.allotStatusList = data.bill_status // 状态
          this.orderTimeList = data.time_type // 下单时间
          this.areaList = data.cust_area //区域
          this.cust_channel = data.cust_channel //渠道类型
          this.cust_class = data.cust_class // 等级
          this.deliver = data.deliver //司机
          this.order_type = data.order_type //调拨状态

          this.bill_status = data.bill_status //状态
          this.authbutton = data.fun_list
          this.bill_type = data.bill_type //来源
          this.account_type = data.account_type //结算方式
          this.sale_type = data.sale_type //销售类型
          this.print = data.print //打印
          this.loadingS = false
        } else {
          this.$message.error(res.msg)
          this.loadingS = false
        }
      })
      let lineData = await clientLineList()
      this.lineSelect = lineData.data

      // postAction('/baseAdmin/line/index').then((res) => {
      // })
      // // 获取表格数据
      // getAdvanceOrderTableList().then((res) => {
      //   console.log(res)
      // })
      // this.fetchData()
    },
    mounted() {
      // 获取url参数
      console.log('获取url参数', this.$route)
      if (this.$route.query.status) {
        this.queryForm.bill_status = Number(this.$route.query.status)
        this.fetchData()
      } else {
        let keys = Object.keys(this.$route.query)
        if (keys) {
          keys.forEach((key) => {
            console.log('字段', key)
            console.log('值:', this.$route.query[key])
            this.queryForm[key] = Number(this.$route.query[key])
          })
          this.fetchData()
        } else {
          this.fetchData()
        }
      }
    },
    methods: {
      /*
       * @docx批量汇总
       * */
      handlerSummary() {
        if (this.selectRows) {
          this.$refs.order.dialogFormVisible = true
          const select_id = this.selectRows.map((list) => {
            return list.id
          })
          console.log(this.selectRows, select_id)
          this.$refs.order.form.order_ids = select_id.join(',')
          this.$refs.order.handlerinfo()
        } else {
          this.$message({ type: 'warning', message: '请先勾选' })
        }
      },
      settimefetchData() {
        this.initTime()
        this.fetchData()
      },
      initTime() {
        this.queryForm.time = day_nM(180)
        this.queryForm.start_time = this.queryForm.time[0]
        this.queryForm.end_time = this.queryForm.time[1]
        // console.log('day_n(90)', day_n(90))
      },
      // 表格合计
      getSummaries(param) {
        const { columns, data } = param
        console.log(columns, data, '合计')
        // 需要合计的下标
        let i = [8, 9, 10]
        let sums = []
        // let val = data.map((item) => {
        //   console.log(item)
        // })
        // columns.forEach((col, idx) => {
        //   i.forEach((n) => {
        //     if (idx == n) {
        //       console.log(col.property)
        //       const val = data.map((item) => {
        //         if (item.goods_type != 1) {
        //           return 0
        //         } else {
        //           return Number(item[col.property])
        //         }
        //       })
        //       console.log(val)
        //       if (!val.every((value) => isNaN(value))) {
        //         const a = val.reduce((total, num) => {
        //           console.log('col', col)
        //           if (!isNaN(total)) {
        //             return total + num
        //           }
        //         })
        //         if (n == 7) {
        //           sums[n] = a.toFixed(2) + ' 元'
        //         } else {
        //           sums[n] = a.toFixed(0)
        //         }
        //         this.allSum = a.toFixed(2)
        //       }
        //     }
        //   })
        //   if (idx == 0) {
        //     sums[idx] = '总计'
        //   }
        // })
        columns.forEach((list, index) => {
          if (list.label == '订单金额') {
            sums[index] = this.totalAll.total_amount
          }
          if (list.label == '应收金额') {
            sums[index] = this.totalAll.receiv_money
          }
          if (list.label == '实收金额') {
            sums[index] = this.totalAll.pay_amount
          }
        })
        // sums[8] = this.totalAll.total_amount
        // sums[9] = this.totalAll.receiv_money
        // sums[10] = this.totalAll.pay_amount
        return sums
      },
      async querySearch(val, cb) {
        let selectData = this.selectRes.data.client
        // let clientRes = await request({
        //   url: '/advance/clientSelect',
        //   method: 'get',
        // })
        // this.selectRes = clientRes.data.client
        console.log(selectData)
        var results = val
          ? selectData.filter(this.createStateFilter(val))
          : selectData
        cb(results)
      },
      // 表格内查看
      checkOrder(row) {
        console.log(row)
        this.$refs['checkOrder'].showDialog()
        let text = ''
        if (row.check_status == 0) {
          text = row.bill_status_text
        } else {
          text = row.check_status_text
        }
        this.$refs['checkOrder'].orderStatus2 = text
        // this.$refs['checkOrder'].orderStatus = row.bill_status_text
        this.$refs['checkOrder'].id = row.id
      },
      createStateFilter(queryString) {
        return (state) => {
          return (
            state.value.toLowerCase().indexOf(queryString.toLowerCase()) === 0
          )
        }
      },
      // client btn click
      iconClick() {
        console.log('click search')
        console.log(this.queryForm.title) // client value
      },
      // top 按钮事件
      btnSummaryOrder() {
        this.$refs['summaryOrder'].showEdit()
      },
      handleSizeChange(val) {
        this.queryForm.pageSize = val
        this.fetchData()
      },
      getRowKey(row) {
        return row.id
      },
      btnAddOrder() {
        // this.$refs['addOrder'].showDialog()
        this.$router.push({
          name: 'AddAO',
          params: { btnShow: this.btnShow },
        })
      },
      btnExpotr() {
        let form = JSON.parse(JSON.stringify(this.queryForm))
        form.pageSize = -1
        getAdvanceOrderTableList(form).then((res) => {
          const list = res.data
          const header = this.checkList
          const columns = this.columns
          exportExcel(header, list, columns, true, '预订单')
        })
      },
      btnPrint() {
        if (!this.selectRows) {
          this.$message.error('请勾选需要打印的选项')
        } else {
          let ids = []
          this.selectRows.forEach((i) => ids.push(i.id))
          this.data_id = ids.join(',')
          this.$refs['RuilangDisplayDialog'].batch = true
          this.$refs['RuilangDisplayDialog'].dialogFormVisible = true
          this.toggleSelection()
        }
      },
      toggleSelection() {
        this.$refs.tableSort.clearSelection()
        this.selectRows = ''
      },
      btnQuery() {
        console.log('chaxun')

        this.toggleSelection()
        if (this.queryForm.time) {
          Object.assign(this.queryForm, {
            start_time: this.queryForm.time[0],
            end_time: this.queryForm.time[1],
          })
        } else {
          Object.assign(this.queryForm, {
            start_time: '',
            end_time: '',
          })
        }

        console.log(this.queryForm)

        this.queryForm.pageNo = 1
        this.fetchData()
      },
      handleSelect(val, e) {
        console.log(val)
      },
      // 勾选表格触发事件
      setSelectRows(val) {
        console.log(val)
        this.selectRows = val
      },
      showdaohuo(row) {
        console.log(row)
        if (row.account_type == 3) {
          console.log('走3这个的是什么')
          this.$refs['arrivalPay'].isshowDialog = true
          this.$refs['arrivalPay'].id = row.id
        } else {
          console.log('不走3这个的是什么')
          this.$refs['arrival'].isshowDialog = true
          this.$refs['arrival'].id = row.id
        }
      },
      handleAdd() {
        this.$refs['edit'].showEdit()
      },
      handleCopy(row) {
        console.log('复制')
        this.$refs['orderTableEdit'].showEdit(row)
        this.$refs['orderTableEdit'].id = row.id
        this.$refs['orderTableEdit'].isCopy = true
        // this.$refs['orderTableEdit'].orderStatus = row.bill_status_text
        console.log(row.id)
      },
      handleAddDuijiang() {
        this.$refs.duiJiang.showDialog = true
      },
      handleEdit(row) {
        // console.log('编辑')
        this.$router.push({
          name: 'orderTableEdit',
          params: { row: row, btnShow: this.btnShow },
        })
        // this.$refs['orderTableEdit'].showEdit(row)
        // this.$refs['orderTableEdit'].id = row.id
        // this.$refs['orderTableEdit'].isCopy = false
        // this.$refs['orderTableEdit'].orderStatus = row.bill_status_text
        // console.log(row.id)
      },
      handleSendOrder(row) {
        console.log('派单', row)
        // this.$router.push({
        //   name: 'ArriveAo',
        //   params: { row: row },
        // })
        this.$refs['sendOrder'].id = row.id
        this.$refs['sendOrder'].showDialog = true
        // this.$refs['sendOrder'].orderStatus = row.bill_status_text
      },
      handlePrint(row) {
        console.log('打印', row)
        this.data_id = row.id
        this.$refs['RuilangDisplayDialog'].batch = false

        this.$refs['RuilangDisplayDialog'].dialogFormVisible = true
      },
      handleCancel(row) {
        console.log('取消派单', row)
        this.$refs['edit'].showEdit(row)
      },
      chooseBrand(val) {
        this.queryForm.brand_id = val
      },
      // 取消派单
      handleUnSendOrder(row) {
        this.currentRow = JSON.parse(JSON.stringify(row))
        // row.showUnSendOrderDialog = true
        beforeUnSendOrder({ id: row.id }).then((res) => {
          console.log('res', res)
          // 如果date不为空，且distribute_only=0，显示取消派单并撤销调拨，仅取消派单两个按钮
          // 如果date不为空，且distribute_only=1，显示取消派单并撤销调拨一个按钮
          // 如果date为空，显示再想想，确定两个按钮
          if (res.data == '') {
            this.$baseConfirm(
              '你确定要取消派单吗',
              {
                confirmButtonText: '确定',
                cancelButtonText: '再想想',
                type: 'warning',
              },
              async () => {
                const { msg } = await unSendOrder({ id: row.id })
                this.$baseMessage(msg, 'success')
                await this.fetchData()
              }
            )
          } else {
            if (res.msg == 0) {
              this.showSendBtn = 1
            } else if (res.msg == 1) {
              this.showSendBtn = 0
            }
            this.$set(row, 'showUnSendOrderDialog', true)
            this.orderCode = res.data
            this.showDialog2 = true
          }
        })
      },
      cancelOrderAnd() {
        this.loading = true
        unSendOrder({ id: this.currentRow.id, is_allot: 1 })
          .then((res) => {
            this.$message.success('取消派单并撤销调拨成功')
            this.fetchData()
            this.showDialog2 = false
            this.loading = false
          })
          .catch(() => {
            this.loading = false
          })
      },
      onlyCancelOrder() {
        unSendOrder({ id: this.currentRow.id }).then((res) => {
          this.$message.success('取消派单成功')
          this.fetchData()
          this.showDialog2 = false
        })
      },
      // 作废
      handleDelete(row) {
        if (row.id) {
          this.$baseConfirm('你确定要作废预订单吗', null, async () => {
            const { msg } = await invalidOrder({ id: row.id })
            this.$baseMessage(msg, 'success')
            await this.fetchData()
          })
        } else {
          // if (this.selectRows.length > 0) {
          //   const ids = this.selectRows.map((item) => item.id).join()
          //   this.$baseConfirm('你确定要删除选中项吗', null, async () => {
          //     const { msg } = await doDelete({ ids: ids })
          //     this.$baseMessage(msg, 'success')
          //     await this.fetchData()
          //   })
          // } else {
          //   this.$baseMessage('未选中任何行', 'error')
          //   return false
          // }
        }
      },
      // 取消到货
      cancelArrival(row) {
        this.$confirm(
          '取消到货后，现销售单作废，车存复原（退货如已退库，需手动作废退库调拨单），原预订单需重新做到货',
          '确定要取消到货？',
          {
            confirmButtonText: '确定',
            cancelButtonText: '再想想',
          }
        ).then((r) => {
          saleCancelArrival({ id: row.id, type: 2 }).then((res) => {
            this.$message.success(res.msg)
            this.fetchData()
          })
        })
      },
      handleCurrentChange(val) {
        this.queryForm.pageNo = val
        this.fetchData()
      },
      async fetchData() {
        console.log('fetchData()')
        this.listLoading = true
        let quform = JSON.parse(JSON.stringify(this.queryForm))
        quform.staff_id = quform.staff_id.join(',')
        const { data, totalCount, msg } = await getAdvanceOrderTableList(quform)
        console.log(data)
        this.total = Number(totalCount) || 0
        this.totalAll = msg
        this.list = data
        this.listLoading = false
      },
      // 客户输入框下拉选择事件
      selectVal(val) {
        console.log('selectVal')
        console.log(val)
        if (val.cust_name) {
          this.queryForm.keyword = val
        } else {
          this.queryForm.keyword = val
        }
      },
      handleImport() {
        this.$refs.importTpl.data.type = 11
        this.$refs.importTpl.title = '批量导入预订单'
        this.$refs.importTpl.showDialog = true
      },
      custInput(v) {
        this.queryForm.keyword = v
      },
      selectId(val) {
        console.log('selectId')
        console.log(val)
        if (val.cust_id) {
          this.queryForm.cust_id = val
          this.queryForm.keyword = ''
        } else {
          this.queryForm.cust_id = val
          this.queryForm.keyword = ''
        }
      },
      clearInput() {
        this.queryForm.cust_id = ''
      },
      handleReview(row) {
        this.$router.push({
          name: 'CheckAo',
          params: {
            row: row,
          },
        })
        // this.$refs.review.id = row.id
        // this.$refs.review.showDialog = true
      },
      handleBatchArrival() {
        this.$router.push({
          path: 'batchArrival',
        })
      },
      // 获取一开始表格cloumns数据
      async getTableWidth() {
        let { data } = await postAction('/setAdmin/config/form-set-list', {
          form_type: 1,
        })
        console.log('data', data)
        let checkLists = []
        data.forEach((i) => {
          if (i.width == 0) {
            i.width = ''
          }
          if (!i.hidden) {
            checkLists.push(i.label)
          }
        })
        if (checkLists.length == 0) {
          this.checkList = [
            '单号',
            '客户名称',
            '来源',
            '出货仓库',
            '业务员',
            '送货人',
            '订单金额',
            '应收金额',
            '实收金额',
            '状态',
            '下单时间',
            '备注',
            '打印数',
          ]
        } else {
          this.checkList = checkLists
          console.log(checkLists)
        }
        if (data.length == 0) {
          console.log('===================')
          this.columns = [
            {
              order: 2,
              label: '单号',
              width: '160',
              prop: 'bill_code',
              sortable: false,
            },
            {
              order: 3,
              label: '客户名称',
              width: '160',
              prop: 'cust_name',
              sortable: false,
            },
            {
              order: 4,
              label: '来源',
              width: '',
              prop: 'bill_type_text',
              sortable: false,
            },
            {
              order: 5,
              label: '出货仓库',
              width: '',
              prop: 'depot_name',
              sortable: false,
            },
            {
              order: 6,
              label: '业务员',
              width: '',
              prop: 'staff_name',
              sortable: false,
            },
            {
              order: 7,
              label: '送货人',
              width: '',
              prop: 'deliver_name',
              sortable: false,
            },
            {
              order: 8,
              label: '订单金额',
              width: '',
              prop: 'total_amount',
              sortable: false,
            },
            {
              order: 9,
              label: '应收金额',
              width: '',
              prop: 'receiv_money',
              sortable: false,
            },
            {
              order: 10,
              label: '实收金额',
              width: '',
              prop: 'pay_amount',
              sortable: false,
            },
            {
              order: 11,
              label: '状态',
              width: '',
              prop: 'bill_status_text',
              sortable: false,
            },
            {
              order: 12,
              label: '下单时间',
              width: '',
              prop: 'create_at',
              sortable: false,
            },
            {
              order: 12.5,
              label: '备注',
              width: '',
              prop: 'remark',
              sortable: false,
            },
            {
              order: 13,
              label: '打印数',
              width: '',
              prop: 'print_num',
              sortable: false,
            },
          ]
        } else {
          this.columns = data
          console.log(this.columns)
        }
      },
      // 表格宽度发生变化
      async widthChange(newWidth, oldWidth, column, event) {
        console.log(newWidth, oldWidth, column, event)
        let a = this.columns.filter((i) => i.label == column.label)
        a[0].width = newWidth
        console.log('表格宽度发生变化', a)
        this.columns.forEach((i) => {
          if (i.label == column.label) {
            i.width = newWidth
          }
        })
        console.log('this.columns', this.columns)
        let { data } = await postAction('/setAdmin/config/form-set', {
          form_type: 1,
          form_data: JSON.stringify(this.columns),
        })
      },
      async filterChcekboxChange(v, io) {
        console.log('v', v)
        console.log('io', io.target.value)
        this.columns.forEach((i) => {
          if (i.label == io.target.value) {
            i.hidden = !v
          }
        })
        let { data } = await postAction('/setAdmin/config/form-set', {
          form_type: 1,
          form_data: JSON.stringify(this.columns),
        })
      },
    },
  }
</script>

<style lang="scss" scoped>
  .table-wrap {
    ::v-deep {
      i {
        cursor: pointer;
      }
    }
  }
  .topInput {
    display: flex;
    justify-content: flex-start;
    div {
      margin-right: 15px;
    }
  }
  .table-wrap {
    margin-top: 10px;
  }
  .setFilter {
    top: 39px;
  }
  .icon-btn {
    position: absolute;
    top: 2px;
    right: 1px;
    border: 0px solid transparent;
  }
</style>
<style lang="scss">
  .custom-table-checkbox {
    .el-checkbox {
      display: block !important;
      margin: 0 0 $base-padding/4 0;
    }
  }
  .red {
    color: red;
  }
</style>
