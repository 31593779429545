var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "wrapper" },
    [
      _c(
        "el-dialog",
        {
          attrs: {
            modal: false,
            "close-on-click-modal": false,
            title: "选择客户",
            top: "15vh",
            visible: _vm.inputWindow,
            width: "1250px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.inputWindow = $event
            },
            close: _vm.close,
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "form",
              staticStyle: { "margin-bottom": "10px" },
              attrs: { "label-width": "80px", inline: true },
            },
            [
              _c(
                "el-form-item",
                [
                  _c("el-input", {
                    staticStyle: { width: "250px", margin: "0px 10px 0px 0" },
                    attrs: { placeholder: "输入门店,编号" },
                    model: {
                      value: _vm.filterText,
                      callback: function ($$v) {
                        _vm.filterText = $$v
                      },
                      expression: "filterText",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "片区" } },
                [
                  _c("el-cascader", {
                    attrs: { options: _vm.areaOpt },
                    model: {
                      value: _vm.area,
                      callback: function ($$v) {
                        _vm.area = $$v
                      },
                      expression: "area",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  staticStyle: { margin: "0 0 0 10px" },
                  attrs: { label: "渠道" },
                },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { placeholder: "请选择" },
                      model: {
                        value: _vm.qudao,
                        callback: function ($$v) {
                          _vm.qudao = $$v
                        },
                        expression: "qudao",
                      },
                    },
                    _vm._l(_vm.qudaoList, function (item) {
                      return _c("el-option", {
                        key: item.value,
                        attrs: { label: item.label, value: item.value },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-button",
                    {
                      staticStyle: { margin: "0 10px" },
                      on: { click: _vm.filter },
                    },
                    [_vm._v("查询")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-table",
            {
              attrs: { stripe: "", data: _vm.list, height: "310" },
              on: {
                "selection-change": _vm.setSelectRows,
                "cell-click": _vm.cellClick,
              },
            },
            [
              _c("el-table-column", {
                attrs: { align: "center", type: "selection", width: "60" },
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "",
                  align: "center",
                  label: "序号",
                  "min-width": "50px",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var $index = ref.$index
                      return [_c("span", [_vm._v(_vm._s($index + 1))])]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "goods_name",
                  align: "center",
                  label: "商品名称",
                  "min-width": "auto",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "code",
                  align: "center",
                  label: "小单位条码",
                  "min-width": "120px",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "classes",
                  align: "center",
                  label: "类别",
                  "min-width": "auto",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "brand",
                  align: "center",
                  label: "品牌",
                  "min-width": "auto",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "inventory",
                  align: "center",
                  label: "可用库存",
                  "min-width": "auto",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "units",
                  align: "center",
                  label: "单位换算",
                  "min-width": "auto",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "status",
                  align: "center",
                  label: "状态",
                  "min-width": "auto",
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [_c("el-button", { on: { click: _vm.close } }, [_vm._v("取 消")])],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }