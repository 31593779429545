var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        modal: false,
        "close-on-click-modal": false,
        title: "派单成功",
        visible: _vm.showDialog,
        width: "800px",
      },
      on: {
        "update:visible": function ($event) {
          _vm.showDialog = $event
        },
      },
    },
    [
      _c(
        "div",
        { staticClass: "wrapper", staticStyle: { overflow: "auto" } },
        [
          _vm._l(_vm.datalist, function (item, index) {
            return _c(
              "div",
              {
                key: index,
                staticClass: "item",
                staticStyle: {
                  "margin-bottom": "10px",
                  overflow: "hidden",
                  "border-bottom": "1px dashed #ccc",
                },
              },
              [
                _c(
                  "div",
                  {
                    staticClass: "top-tip",
                    staticStyle: { overflow: "hidden", "line-height": "30px" },
                  },
                  [
                    _c("div", { staticStyle: { float: "left" } }, [
                      _vm._v(" 出货仓库: "),
                      _c("span", [_vm._v(_vm._s(item.depot_name))]),
                    ]),
                    _c(
                      "div",
                      { staticStyle: { float: "left", margin: "0 15px" } },
                      [
                        _vm._v(" 派送司机: "),
                        _c("span", [_vm._v(_vm._s(item.driver_name))]),
                      ]
                    ),
                    item.allot_code && item.allot_code !== ""
                      ? _c("div", { staticStyle: { float: "right" } }, [
                          _vm._v(" 调拨单: "),
                          _c("span", [_vm._v(_vm._s(item.allot_code))]),
                        ])
                      : _vm._e(),
                  ]
                ),
                _c(
                  "el-table",
                  { attrs: { stripe: "", border: "", data: item.order_list } },
                  [
                    _c("el-table-column", {
                      attrs: {
                        align: "center",
                        prop: "bill_code",
                        label: "单号",
                        width: "160",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        align: "center",
                        prop: "cust_name",
                        label: "客户名称",
                        width: "170",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        align: "center",
                        prop: "cust_address",
                        label: "地址",
                        width: "auto",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "el-table",
                  {
                    staticStyle: { "margin-top": "10px" },
                    attrs: { stripe: "", border: "", data: item.goods },
                  },
                  [
                    _c("el-table-column", {
                      attrs: {
                        align: "center",
                        prop: "goods_name",
                        label: "商品名称",
                        width: "auto",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        align: "center",
                        prop: "specs",
                        label: "规格",
                        width: "170",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        align: "center",
                        prop: "sale_num",
                        label: "销售",
                        width: "160",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        align: "center",
                        prop: "give_num",
                        label: "赠送",
                        width: "160",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        align: "center",
                        prop: "sum_num",
                        label: "合计",
                        width: "160",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "el-button",
                  {
                    staticStyle: {
                      float: "right",
                      "margin-top": "10px",
                      "margin-left": "10px",
                    },
                    attrs: { type: "warning" },
                    on: {
                      click: function ($event) {
                        return _vm.print(item)
                      },
                    },
                  },
                  [_vm._v(" 打印 ")]
                ),
              ],
              1
            )
          }),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              staticStyle: { "margin-top": "25px", "text-align": "right" },
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [_c("el-button", { on: { click: _vm.close } }, [_vm._v("关闭")])],
            1
          ),
        ],
        2
      ),
      _c("ruilang-display-dialog", {
        ref: "RuilangDisplayDialog",
        attrs: {
          bill_type: "LHZ",
          data_id: 1,
          type: 1,
          "summary-params": _vm.sumdata,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }