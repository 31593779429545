var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        {
          attrs: {
            modal: false,
            "close-on-click-modal": false,
            title: _vm.title,
            top: "5vh",
            visible: _vm.dialogFormVisible,
            width: "1380px",
            "modal-append-to-body": false,
            fullscreen: "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogFormVisible = $event
            },
            close: _vm.close,
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "form",
              attrs: {
                model: _vm.form,
                rules: _vm.rules,
                "label-width": "80px",
                inline: true,
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "购货方", prop: "cust_name" } },
                [
                  _c("client-search", {
                    ref: "clientSearch",
                    attrs: {
                      keyword: _vm.form.cust_name,
                      "popper-class": "selectDC",
                      ziobj: {
                        is_closed: 0,
                      },
                    },
                    on: {
                      "update:keyword": function ($event) {
                        return _vm.$set(_vm.form, "cust_name", $event)
                      },
                      "input-mine": _vm.custInput,
                      "select-val": _vm.setCustName,
                      "select-val-alldata": _vm.setAllInput,
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  staticStyle: { "margin-right": "10px" },
                  attrs: { label: "联系人", prop: "lxr" },
                },
                [
                  _c("el-input", {
                    staticStyle: { width: "209px" },
                    attrs: { disabled: "" },
                    model: {
                      value: _vm.form.lxr,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.form,
                          "lxr",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "form.lxr",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { staticClass: "dz", attrs: { label: "地址", prop: "dz" } },
                [
                  _c("el-input", {
                    staticStyle: { width: "512px", "margin-right": "130px" },
                    attrs: { disabled: "" },
                    model: {
                      value: _vm.form.address,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.form,
                          "address",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "form.address",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "仓库", prop: "depot_id" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { placeholder: "仓库" },
                      on: { change: _vm.changeDepot },
                      model: {
                        value: _vm.form.depot_id,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "depot_id", $$v)
                        },
                        expression: "form.depot_id",
                      },
                    },
                    _vm._l(_vm.allSelectList.depot, function (item_) {
                      return _c("el-option", {
                        key: item_.id,
                        attrs: { label: item_.name, value: item_.id },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "送货人", prop: "deliver_id" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { placeholder: "送货人", clearable: "" },
                      model: {
                        value: _vm.form.deliver_id,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "deliver_id", $$v)
                        },
                        expression: "form.deliver_id",
                      },
                    },
                    _vm._l(_vm.shrList, function (item_) {
                      return _c("el-option", {
                        key: item_.id,
                        attrs: { label: item_.name, value: item_.id },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "业务员", prop: "ywy" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { placeholder: "业务员", clearable: "" },
                      model: {
                        value: _vm.form.staff_id,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "staff_id", $$v)
                        },
                        expression: "form.staff_id",
                      },
                    },
                    _vm._l(_vm.allSelectList.staff, function (item_) {
                      return _c("el-option", {
                        key: item_.id,
                        attrs: { label: item_.name, value: item_.id },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  staticStyle: { "margin-left": "20px" },
                  attrs: { label: "扫码录入", prop: "goods_code" },
                },
                [
                  _c("el-input", {
                    attrs: {
                      disabled: _vm.discode,
                      clearable: "",
                      placeholder: "使用扫码枪扫码添加商品",
                    },
                    nativeOn: {
                      keyup: function ($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        ) {
                          return null
                        }
                        return _vm.handlerkey.apply(null, arguments)
                      },
                    },
                    model: {
                      value: _vm.form.goods_code,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.form,
                          "goods_code",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "form.goods_code",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "快捷输入:" } },
                [
                  _c("el-autocomplete", {
                    staticClass: "inline-input",
                    attrs: {
                      disabled: _vm.discode,
                      "value-key": "goods_name",
                      "fetch-suggestions": _vm.handlerremark,
                      placeholder: "商品名称、简拼、条码",
                      "trigger-on-focus": false,
                    },
                    on: { select: _vm.handleSelect },
                    model: {
                      value: _vm.remark,
                      callback: function ($$v) {
                        _vm.remark = $$v
                      },
                      expression: "remark",
                    },
                  }),
                ],
                1
              ),
              _vm.followuser.length != 0
                ? _c(
                    "el-form-item",
                    { attrs: { label: "随车人" } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: {
                            placeholder: "随车人员",
                            clearable: "",
                            multiple: "",
                          },
                          model: {
                            value: _vm.form.follow_users,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "follow_users", $$v)
                            },
                            expression: "form.follow_users",
                          },
                        },
                        _vm._l(_vm.followuser, function (item_) {
                          return _c("el-option", {
                            key: item_.id,
                            attrs: { label: item_.user_name, value: item_.id },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
          _c(
            "el-table",
            {
              ref: "tableSort",
              attrs: {
                stripe: "",
                data: _vm.list,
                height: "600px",
                "summary-method": _vm.getSummaries,
                "show-summary": "",
                "row-class-name": _vm.tableRowClassName,
              },
              on: { "cell-click": _vm.handlercellclick },
            },
            [
              _c("el-table-column", {
                attrs: { align: "center", type: "selection", width: "90" },
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "",
                  align: "center",
                  label: "序号",
                  "min-width": "50px",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var $index = ref.$index
                      return [_c("span", [_vm._v(_vm._s($index + 1))])]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "type",
                  align: "center",
                  label: "类型",
                  "min-width": "120px",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [
                        row.index === _vm.tabClickIndex &&
                        _vm.tabClickLabel === "类型"
                          ? [
                              _c(
                                "el-select",
                                {
                                  staticStyle: { width: "100px" },
                                  attrs: { placeholder: "选择类型" },
                                  on: {
                                    change: function ($event) {
                                      return _vm.handleTypeChange($event, row)
                                    },
                                  },
                                  model: {
                                    value: row.goods_type,
                                    callback: function ($$v) {
                                      _vm.$set(row, "goods_type", $$v)
                                    },
                                    expression: "row.goods_type",
                                  },
                                },
                                _vm._l(row.arr_goods_type, function (item_) {
                                  return _c("el-option", {
                                    key: item_.id,
                                    attrs: {
                                      label: item_.name,
                                      value: item_.id,
                                    },
                                  })
                                }),
                                1
                              ),
                            ]
                          : _c("div", [
                              _vm._v(" " + _vm._s(row.type) + " "),
                              _c("i", {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: !row.type,
                                    expression: "!row.type",
                                  },
                                ],
                                staticClass: "el-icon-caret-right trsher",
                              }),
                            ]),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "goods_name",
                  align: "center",
                  label: "商品名称",
                  "min-width": "260px",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var $index = ref.$index
                      var row = ref.row
                      return [
                        row.index === _vm.tabClickIndex &&
                        _vm.tabClickLabel === "商品名称"
                          ? [
                              _c("goods-search", {
                                attrs: {
                                  goods_keyword: row.goods_name,
                                  "f-key": row.goods_name,
                                  "goods-id": row.goods_id,
                                  kouwei: row.child,
                                  rowf: row,
                                  "depot-id": _vm.form.depot_id,
                                  disab: _vm.tableEdit,
                                  "cust-id": _vm.form.id,
                                },
                                on: {
                                  "add-rows": function ($event) {
                                    return _vm.addRows(row, $event)
                                  },
                                  "select-goods-all": function ($event) {
                                    return _vm.selectGoods($event, row, $index)
                                  },
                                  "change-kouwei": function ($event) {
                                    return _vm.changeKouwei($event, row)
                                  },
                                },
                              }),
                            ]
                          : _c("div", { staticClass: "goodsname" }, [
                              _c(
                                "div",
                                {
                                  class: row.isDuplicate
                                    ? "namewithy"
                                    : "namewith",
                                },
                                [_vm._v(" " + _vm._s(row.goods_name) + " ")]
                              ),
                            ]),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "specs",
                  align: "center",
                  label: "规格",
                  "min-width": "110px",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [_vm._v(" " + _vm._s(row.specs) + " ")]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "unit_code",
                  align: "center",
                  label: "条码",
                  "min-width": "110px",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [_vm._v(" " + _vm._s(_vm.currUnitCode(row)) + " ")]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "type",
                  align: "center",
                  label: "单位",
                  width: "140px",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      var $index = ref.$index
                      return [
                        row.index === _vm.tabClickIndex &&
                        _vm.tabClickLabel === "单位"
                          ? [
                              _c(
                                "el-select",
                                {
                                  staticStyle: { width: "90px" },
                                  attrs: {
                                    placeholder: "选择单位",
                                    disabled: row.goods_name == "",
                                  },
                                  on: {
                                    change: function ($event) {
                                      _vm.unitChange($event, row),
                                        _vm.moneyN(row, $index)
                                    },
                                  },
                                  model: {
                                    value: row.unit_id,
                                    callback: function ($$v) {
                                      _vm.$set(row, "unit_id", $$v)
                                    },
                                    expression: "row.unit_id",
                                  },
                                },
                                _vm._l(row.arr_unit, function (item) {
                                  return _c("el-option", {
                                    key: item.id,
                                    attrs: {
                                      label: item.unit_name,
                                      value: item.id,
                                    },
                                  })
                                }),
                                1
                              ),
                            ]
                          : _c("div", [
                              _c("span", [_vm._v(_vm._s(row.unit_name))]),
                              _c("i", {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: !row.unit_name,
                                    expression: "!row.unit_name",
                                  },
                                ],
                                staticClass: "el-icon-caret-right trsher",
                              }),
                            ]),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "goods_price",
                  align: "center",
                  label: "单价",
                  "min-width": "110px",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var $index = ref.$index
                      var row = ref.row
                      return [
                        row.index === _vm.tabClickIndex &&
                        _vm.tabClickLabel === "单价"
                          ? [
                              _c("el-input", {
                                ref: "input",
                                staticStyle: { width: "80px" },
                                attrs: {
                                  disabled:
                                    row.goods_name == "" ||
                                    _vm.isEditPrice == 0,
                                  onkeyup:
                                    "value=value.replace(/[^\\d.]/g,'') ",
                                },
                                on: {
                                  input: function ($event) {
                                    return _vm.moneyN(row, $index)
                                  },
                                },
                                model: {
                                  value: row.goods_price,
                                  callback: function ($$v) {
                                    _vm.$set(row, "goods_price", $$v)
                                  },
                                  expression: "row.goods_price",
                                },
                              }),
                            ]
                          : _c("div", [
                              _vm._v(" " + _vm._s(row.goods_price) + " "),
                              _c("i", {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: !row.goods_price,
                                    expression: "!row.goods_price",
                                  },
                                ],
                                staticClass: "el-icon-caret-right trsher",
                              }),
                            ]),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "quantity",
                  align: "center",
                  label: "订购数量",
                  "min-width": "110px",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var $index = ref.$index
                      var row = ref.row
                      return [
                        row.index === _vm.tabClickIndex &&
                        _vm.tabClickLabel === "订购数量"
                          ? [
                              _c("el-input", {
                                staticStyle: { width: "80px" },
                                attrs: {
                                  disabled: row.goods_name == "",
                                  onkeyup:
                                    "value=value.replace(/[^\\d.]/g,'') ",
                                },
                                on: {
                                  input: function ($event) {
                                    return _vm.moneyN(row, $index)
                                  },
                                  focus: function ($event) {
                                    return _vm.inputfocus(row)
                                  },
                                },
                                model: {
                                  value: row.quantity,
                                  callback: function ($$v) {
                                    _vm.$set(row, "quantity", $$v)
                                  },
                                  expression: "row.quantity",
                                },
                              }),
                            ]
                          : _c("div", [
                              _vm._v(" " + _vm._s(row.quantity) + " "),
                              _c("i", {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: !row.quantity,
                                    expression: "!row.quantity",
                                  },
                                ],
                                staticClass: "el-icon-caret-right trsher",
                              }),
                            ]),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "depot_stock_num_read",
                  align: "center",
                  label: "可用库存",
                  "min-width": "110px",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [
                        _vm._v(" " + _vm._s(row.depot_stock_num_read) + " "),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "balance_count_read",
                  align: "center",
                  label: "实际库存",
                  "min-width": "110px",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [
                        _vm._v(" " + _vm._s(row.balance_count_read) + " "),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "goods_money",
                  align: "center",
                  label: "金额",
                  "min-width": "90px",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var $index = ref.$index
                      var row = ref.row
                      return [
                        row.index === _vm.tabClickIndex &&
                        _vm.tabClickLabel === "金额" &&
                        row.quantity > 0
                          ? [
                              _c("el-input", {
                                ref: "input",
                                staticStyle: { width: "80px" },
                                attrs: {
                                  disabled:
                                    row.goods_name == "" ||
                                    _vm.isEditPrice == 0 ||
                                    row.quantity == 0,
                                  onkeyup:
                                    "value=value.replace(/[^\\d.]/g,'') ",
                                },
                                on: {
                                  input: function ($event) {
                                    return _vm.moneyreverse(row, $index)
                                  },
                                },
                                model: {
                                  value: row.goods_money,
                                  callback: function ($$v) {
                                    _vm.$set(row, "goods_money", $$v)
                                  },
                                  expression: "row.goods_money",
                                },
                              }),
                            ]
                          : _c("div", [
                              _vm._v(" " + _vm._s(row.goods_money) + " "),
                              _c("i", {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: !row.goods_money,
                                    expression: "!row.goods_money",
                                  },
                                ],
                                staticClass: "el-icon-caret-right trsher",
                              }),
                            ]),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "goods_production_date",
                  align: "center",
                  label: "生产日期",
                  "min-width": "140px",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [
                        row.index === _vm.tabClickIndex &&
                        _vm.tabClickLabel === "生产日期"
                          ? _c("el-date-picker", {
                              staticStyle: { width: "130px" },
                              attrs: { "value-format": "yyyy-MM-dd" },
                              model: {
                                value: row.goods_production_date,
                                callback: function ($$v) {
                                  _vm.$set(row, "goods_production_date", $$v)
                                },
                                expression: "row.goods_production_date",
                              },
                            })
                          : _c("div", [
                              _vm._v(
                                " " + _vm._s(row.goods_production_date) + " "
                              ),
                              _c("i", {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: !row.goods_production_date,
                                    expression: "!row.goods_production_date",
                                  },
                                ],
                                staticClass: "el-icon-caret-right trsher",
                              }),
                            ]),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "info",
                  align: "center",
                  label: "备注",
                  "min-width": "110px",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [
                        row.index === _vm.tabClickIndex &&
                        _vm.tabClickLabel === "备注"
                          ? _c("el-input", {
                              model: {
                                value: row.info,
                                callback: function ($$v) {
                                  _vm.$set(row, "info", $$v)
                                },
                                expression: "row.info",
                              },
                            })
                          : _c("div", [
                              _vm._v(" " + _vm._s(row.info) + " "),
                              _c("i", {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: !row.info,
                                    expression: "!row.info",
                                  },
                                ],
                                staticClass: "el-icon-caret-right trsher",
                              }),
                            ]),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  align: "center",
                  label: "操作",
                  fixed: "right",
                  width: "160",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var $index = ref.$index
                      var row = ref.row
                      return [
                        _c(
                          "el-button",
                          {
                            attrs: { type: "text" },
                            nativeOn: {
                              click: function ($event) {
                                $event.preventDefault()
                                return _vm.copyRow($index, row)
                              },
                            },
                          },
                          [_vm._v(" 复制 ")]
                        ),
                        _c(
                          "el-button",
                          {
                            attrs: { type: "text" },
                            nativeOn: {
                              click: function ($event) {
                                $event.preventDefault()
                                return _vm.deleteRow($index, row)
                              },
                            },
                          },
                          [_vm._v(" 删除 ")]
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _c(
            "el-form",
            {
              ref: "form2",
              staticStyle: { "margin-top": "15px" },
              attrs: { model: _vm.form2, "label-suffix": "：", inline: true },
            },
            [
              _c(
                "el-form-item",
                {
                  staticStyle: { width: "100%" },
                  attrs: { label: "备注", prop: "remark" },
                },
                [
                  _c("el-input", {
                    staticStyle: { width: "530px" },
                    model: {
                      value: _vm.form2.remark,
                      callback: function ($$v) {
                        _vm.$set(_vm.form2, "remark", $$v)
                      },
                      expression: "form2.remark",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "订单金额", prop: "dgze" } },
                [_vm._v(" " + _vm._s(_vm.form2.dgze) + " ")]
              ),
              _c(
                "el-form-item",
                { attrs: { label: "退货金额", prop: "thje" } },
                [_vm._v(" " + _vm._s(_vm.form2.thje) + " ")]
              ),
              _c(
                "div",
                [
                  _c(
                    "el-form-item",
                    [
                      _c("span", {
                        staticClass: "el-icon-plus",
                        staticStyle: { color: "red", fontweight: "bold" },
                      }),
                      _c(
                        "el-form-item",
                        { attrs: { label: "销售金额", prop: "xsje" } },
                        [_vm._v(" " + _vm._s(_vm.form2.xsje) + " ")]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    [
                      _c("span", {
                        staticClass: "el-icon-minus",
                        staticStyle: { color: "#41d741", fontweight: "bold" },
                      }),
                      _c(
                        "el-form-item",
                        { attrs: { label: "优惠", prop: "yhje" } },
                        [
                          _c("el-input", {
                            attrs: { max: Number(_vm.form2.xsje) },
                            on: { input: _vm.yhInput, focus: _vm.handlerclero },
                            model: {
                              value: _vm.form2.yhje,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.form2,
                                  "yhje",
                                  typeof $$v === "string" ? $$v.trim() : $$v
                                )
                              },
                              expression: "form2.yhje",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "优惠后", prop: "yhh" } },
                    [
                      _c("el-input", {
                        attrs: { min: 0, max: 1000000000, type: "number" },
                        on: { focus: _vm.handlerclero, input: _vm.yhhInput },
                        model: {
                          value: _vm.form2.yhh,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.form2,
                              "yhh",
                              typeof $$v === "string" ? $$v.trim() : $$v
                            )
                          },
                          expression: "form2.yhh",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    [
                      _c("span", {
                        staticClass: "el-icon-minus",
                        staticStyle: { color: "#41d741", fontweight: "bold" },
                      }),
                      _c(
                        "el-form-item",
                        { attrs: { label: "退货退款", prop: "thtk" } },
                        [_vm._v(" " + _vm._s(_vm.form2.thtk) + " ")]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { prop: "refund_type" } },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "186px" },
                          attrs: { placeholder: "退货方式" },
                          model: {
                            value: _vm.form2.refund_type,
                            callback: function ($$v) {
                              _vm.$set(_vm.form2, "refund_type", $$v)
                            },
                            expression: "form2.refund_type",
                          },
                        },
                        _vm._l(
                          _vm.allSelectList.arr_refund_type,
                          function (item_) {
                            return _c("el-option", {
                              key: item_.id,
                              attrs: { label: item_.name, value: item_.id },
                            })
                          }
                        ),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm.form.is_fun
                ? _c(
                    "el-form-item",
                    [
                      _c("span", {
                        staticClass: "el-icon-minus",
                        staticStyle: { color: "#41d741", fontweight: "bold" },
                      }),
                      _c(
                        "el-form-item",
                        { attrs: { label: "费用抵扣", prop: "thtk" } },
                        [
                          _c("el-input", {
                            on: {
                              input: _vm.yshandler,
                              focus: _vm.handlerclero,
                            },
                            model: {
                              value: _vm.form2.fydk,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.form2,
                                  "fydk",
                                  typeof $$v === "string" ? $$v.trim() : $$v
                                )
                              },
                              expression: "form2.fydk",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "186px" },
                          attrs: {
                            placeholder:
                              "请选费用" + "（合计" + _vm.funds_zong + "）",
                            multiple: "",
                            "collapse-tags": "",
                          },
                          on: { change: _vm.handlerfydk },
                          model: {
                            value: _vm.form2.funds_type,
                            callback: function ($$v) {
                              _vm.$set(_vm.form2, "funds_type", $$v)
                            },
                            expression: "form2.funds_type",
                          },
                        },
                        _vm._l(_vm.funselect, function (item_) {
                          return _c("el-option", {
                            key: item_.id,
                            attrs: {
                              label:
                                item_.funds_type +
                                "(金额" +
                                item_.left_amount +
                                ")",
                              value: item_.id,
                            },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "div",
                [
                  _c(
                    "el-form-item",
                    [
                      _c(
                        "span",
                        {
                          staticStyle: { color: "#41d741", fontweight: "bold" },
                        },
                        [_vm._v("=")]
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "应收款", prop: "ysk" } },
                        [_vm._v(" " + _vm._s(_vm.form2.ysk) + " ")]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "结算方式", prop: "account_type" } },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "186px" },
                          attrs: { placeholder: "结算方式" },
                          on: { change: _vm.jsfsChange },
                          model: {
                            value: _vm.form2.account_type,
                            callback: function ($$v) {
                              _vm.$set(_vm.form2, "account_type", $$v)
                            },
                            expression: "form2.account_type",
                          },
                        },
                        _vm._l(_vm.accounttype, function (item_) {
                          return _c("el-option", {
                            key: item_.id,
                            attrs: { label: item_.name, value: item_.id },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._l(_vm.form2.xssk, function (item1, index) {
                    return _c(
                      "el-form-item",
                      {
                        key: index,
                        attrs: { label: "销售收款", "label-width": "160px" },
                      },
                      [
                        _c(
                          "div",
                          { staticStyle: { display: "flex" } },
                          [
                            _c("el-input", {
                              staticStyle: {
                                width: "90px",
                                "margin-right": "20px",
                              },
                              on: { focus: _vm.handlerclero },
                              model: {
                                value: item1.money,
                                callback: function ($$v) {
                                  _vm.$set(
                                    item1,
                                    "money",
                                    typeof $$v === "string" ? $$v.trim() : $$v
                                  )
                                },
                                expression: "item1.money",
                              },
                            }),
                            _c(
                              "el-select",
                              {
                                staticStyle: { width: "90px" },
                                model: {
                                  value: item1.pay_type,
                                  callback: function ($$v) {
                                    _vm.$set(item1, "pay_type", $$v)
                                  },
                                  expression: "item1.pay_type",
                                },
                              },
                              _vm._l(
                                _vm.allSelectList.arr_pay_type,
                                function (item_1) {
                                  return _c("el-option", {
                                    key: item_1.id,
                                    attrs: {
                                      label: item_1.name,
                                      value: item_1.id,
                                    },
                                  })
                                }
                              ),
                              1
                            ),
                          ],
                          1
                        ),
                        index == 0
                          ? _c("i", {
                              staticClass: "el-icon-circle-plus addbtn",
                              on: { click: _vm.addInput },
                            })
                          : _vm._e(),
                      ]
                    )
                  }),
                ],
                2
              ),
            ],
            1
          ),
          _vm.btnShow
            ? _c(
                "div",
                {
                  staticClass: "dialog-footer",
                  attrs: { slot: "footer" },
                  slot: "footer",
                },
                [
                  _vm.btnShow.pre_process_door == 0 &&
                  _vm.btnShow.distribute_only == 0
                    ? [
                        _c(
                          "el-button",
                          {
                            attrs: { type: "warning" },
                            on: { click: _vm.saveAlloct },
                          },
                          [_vm._v(" 添加并生成调拨单 ")]
                        ),
                        _c(
                          "el-button",
                          {
                            attrs: { type: "primary" },
                            on: { click: _vm.save },
                          },
                          [_vm._v("添加")]
                        ),
                      ]
                    : _vm.btnShow.pre_process_door == 0 &&
                      _vm.btnShow.distribute_only == 1
                    ? [
                        _c(
                          "el-button",
                          {
                            attrs: { type: "warning" },
                            on: { click: _vm.saveAlloct },
                          },
                          [_vm._v(" 添加并生成调拨单 ")]
                        ),
                      ]
                    : _vm._e(),
                  _vm.btnShow.pre_process_door == 1
                    ? [
                        _c(
                          "el-button",
                          {
                            attrs: { type: "primary" },
                            on: { click: _vm.save },
                          },
                          [_vm._v("添加")]
                        ),
                      ]
                    : _vm._e(),
                  _c("el-button", { on: { click: _vm.close } }, [
                    _vm._v("取 消"),
                  ]),
                ],
                2
              )
            : _vm._e(),
        ],
        1
      ),
      _c("table-input-window", {
        ref: "tableInputWindow",
        on: { "add-rows": _vm.addRows },
      }),
      _c("input-window", {
        ref: "inputWindow",
        on: { "change-client": _vm.changeClient },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }