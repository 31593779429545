<template>
  <el-dialog
    :modal="false"
    :close-on-click-modal="false"
    title="汇总派单"
    :visible.sync="dialogFormVisible"
    width="1160px"
    fullscreen
    @close="close"
  >
    <div class="wrapper">
      <div class="left">
        <h3>选单{{ showBtn }}</h3>
        <el-form ref="form" :model="form" label-width="80px">
          <div class="topInput">
            <el-select
              v-model="form.depot_id"
              placeholder="选择出货仓库"
              clearable
            >
              <el-option
                v-for="item in chckList"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              ></el-option>
            </el-select>
            <el-select
              v-model="form.staff_ids"
              placeholder="选择业务员选单"
              multiple
              clearable
            >
              <el-option
                v-for="item in ywyList"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              ></el-option>
            </el-select>
            <el-select
              v-model="form.line_id"
              clearable
              style="width: 140px"
              placeholder="请选择路线"
            >
              <el-option
                v-for="(i, idx) in lineSelect"
                :key="idx"
                :value="i.line_id"
                :label="i.line_name"
              ></el-option>
            </el-select>
            <el-date-picker
              v-model="time"
              type="daterange"
              range-separator="-"
              value-format="yyyy-MM-dd"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              style="width: 200px"
            ></el-date-picker>
            <!-- <el-select v-model="form.xl" placeholder="选择线路选单" clearable>
              <el-option
                v-for="item in xlList"
                :key="item.id"
                :label="item.line_name"
                :value="item.id"
              ></el-option>
            </el-select> -->
            <el-button type="primary" @click="handleQuery">查询</el-button>
            <el-button @click="check">勾选</el-button>
            <el-button type="primary" @click="addTable">添加</el-button>
          </div>
        </el-form>
        <!-- 左侧表格 -->
        <el-table
          ref="leftTable"
          v-loading="listLoading"
          stripe
          :data="leftTableData"
          height="550px"
          style="margin-top: 10px"
          @selection-change="setSelectRows"
        >
          <el-table-column
            align="center"
            type="selection"
            width="55"
          ></el-table-column>
          <el-table-column
            v-for="(item, index) in finallyColumns"
            :key="index"
            align="center"
            :label="item.label"
            :sortable="item.sortable"
            width=""
          >
            <template #default="{ row }">
              <span
                :class="item.label == '单号' ? 'underline' : ''"
                @click="handleCheckDetail(row)"
              >
                {{ row[item.prop] }}
              </span>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="middle">
        <i class="el-icon-caret-right" @click="addTable"></i>
      </div>
      <div class="right" style="height: 647px; overflow: auto">
        <h3>派单</h3>
        <div
          v-if="showRtable"
          style="margin-top: 70px; color: #606266; text-align: center"
        >
          暂无数据
        </div>
        <div v-loading="ll"></div>
        <div
          v-for="(item, index) in rightTableData"
          :key="index"
          class="right-content topInfo"
          style="margin-bottom: 15px"
        >
          <div class="inp">
            <div>出货仓库: {{ item.depot_name }}</div>
            <div>
              派送司机:
              <el-select
                v-model="item.deliver"
                placeholder="选择司机"
                clearable
                style="width: 130px"
                @change="chooseDeliver(item, $event)"
              >
                <el-option
                  v-for="item2 in item.arr_deliver"
                  :key="item2.id"
                  :label="item2.name"
                  :value="item2.id"
                ></el-option>
              </el-select>
            </div>
            <div style="width: 120px">车辆: {{ item.carCode }}</div>
          </div>
          <div class="table">
            <el-table stripe :data="item.goods">
              <el-table-column
                v-for="(colItem, index3) in finallyColumns2"
                :key="index3"
                align="center"
                :label="colItem.label"
                :sortable="colItem.sortable"
                :width="colItem.width"
              >
                <template #default="{ row }">
                  <span>{{ row[colItem.prop] }}</span>
                </template>
              </el-table-column>
            </el-table>
          </div>
        </div>
      </div>
    </div>

    <!-- 底部按钮 -->
    <div v-if="btnShow" slot="footer" class="dialog-footer">
      <el-button type="warning" @click="clearR">清空</el-button>
      <!-- <el-button type="warning" :loading="loadingBtn" @click="saveAlloct">
        派单并生成调拨单
      </el-button>
      <el-button v-if="showBtn" type="primary" @click="save">派单</el-button> -->
      <template
        v-if="btnShow.pre_process_door == 0 && btnShow.distribute_only == 0"
      >
        <el-button type="warning" :loading="loadingBtn" @click="saveAlloct">
          派单并生成调拨单
        </el-button>
        <el-button type="primary" @click="save">派单</el-button>
      </template>
      <template
        v-else-if="
          btnShow.pre_process_door == 0 && btnShow.distribute_only == 1
        "
      >
        <el-button type="warning" :loading="loadingBtn" @click="saveAlloct">
          派单并生成调拨单
        </el-button>
      </template>
      <template v-if="btnShow.pre_process_door == 1">
        <el-button type="primary" @click="save">派单</el-button>
      </template>
      <el-button @click="close">取 消</el-button>
    </div>
    <advance-order-detail ref="advanceOrderDetail"></advance-order-detail>
    <sub-dialog
      ref="subDialog"
      :datalist="subData"
      :sumdata="sumdata"
      :cancelid.sync="allId"
      @refresh="getLeftTableData"
      @refresh-index="fetchDataIndex"
    ></sub-dialog>
  </el-dialog>
</template>

<script>
  import {
    getAdvanceOrderTableList,
    subRightTable,
    summarySave,
    summarySaveD,
  } from '@/api/advanceOrder'
  import { getStaffList } from '@/api/setPrice' // 业务员下拉
  import { clientLineList } from '@/api/ClientDetail' // 线路下拉
  //获取出货仓库，退货方式	付款方式	结算方式	下拉
  import { getAllSelect } from '@/api/advanceOrder'

  import _ from 'lodash'
  import SubDialog from './subDialog'
  import advanceOrderDetail from '../checkOrder'
  import { postAction } from '@/api/Employee'
  export default {
    name: 'SummaryOrder',
    components: {
      SubDialog,
      advanceOrderDetail,
    },
    props: {
      btnShow: {
        type: [Boolean, Object],
        default: () => false,
      },
    },
    data() {
      return {
        sumdata: '',
        showBtn: 0,
        dialogFormVisible: false,
        lineSelect: [],
        endId: [],
        allId: '',
        cancelid: '',
        // 子窗口数据
        subData: [],
        chckList: [
          {
            lable: '总仓',
            value: '总仓',
          },
          {
            lable: 'A仓',
            value: 'A仓',
          },
          {
            lable: 'B仓',
            value: 'B仓',
          },
          {
            lable: 'C仓',
            value: 'C仓',
          },
        ],
        ywyList: [
          {
            lable: '张师傅',
            value: '张师傅',
          },
          {
            lable: '李师傅',
            value: '李师傅',
          },
          {
            lable: '王师傅',
            value: '王师傅',
          },
        ],
        xlList: [
          {
            lable: 'A线路',
            value: 'A线路',
          },
          {
            lable: 'B线路',
            value: 'B线路',
          },
          {
            lable: 'C线路',
            value: 'C线路',
          },
          {
            lable: 'D线路',
            value: 'D线路',
          },
        ],
        time: [],
        form: {
          depot_id: '',
          // staff_ids: [],
          start_time: '',
          end_time: '',
          line_id: '',
          is_pai: 1,
        },
        ll: false,
        // 左侧表格
        //选择框数据
        checkedList: [],
        total: 0,
        checkList: [
          '出货仓库',
          '配送要求',
          '单号',
          '客户名称',
          '订单金额',
          '业务员',
          '下单时间',
        ],
        loadingBtn: false,
        columns: [
          {
            order: 1,
            label: '出货仓库',
            width: '100',
            prop: 'depot_name',
            sortable: false,
          },
          {
            order: 2,
            label: '配送要求',
            width: '120',
            prop: 'delivery_ask_text',
            sortable: false,
          },
          {
            order: 3,
            label: '单号',
            width: 'auto',
            prop: 'bill_code',
            sortable: false,
          },
          {
            order: 4,
            label: '客户名称',
            width: '110',
            prop: 'cust_name',
            sortable: false,
          },
          {
            order: 5,
            label: '订单金额',
            width: '100',
            prop: 'total_amount',
            sortable: false,
          },
          {
            order: 6,
            label: '业务员',
            width: '90',
            prop: 'staff_name',
            sortable: false,
          },
          {
            order: 7,
            label: '下单时间',
            width: '110',
            prop: 'create_at',
            sortable: false,
          },
        ],
        showRtable: true,
        listLoading: true,
        leftTableData: [],

        // 右侧数据
        rightListLoading: true,
        cloItem: [
          '类型',
          '商品名称',
          '规格',
          '订货数量',
          '实际库存',
          '可用库存',
        ],
        columns2: [
          {
            order: 1,
            label: '类型',
            width: 'auto',
            prop: 'goods_type_text',
            sortable: false,
          },
          {
            order: 2,
            label: '商品名称',
            width: 'auto',
            prop: 'goods_name',
            sortable: false,
          },
          {
            order: 3,
            label: '规格',
            width: 'auto',
            prop: 'specs',
            sortable: false,
          },
          {
            order: 4,
            label: '订货数量',
            width: 'auto',
            prop: 'quantity',
            sortable: false,
          },
          {
            order: 5,
            label: '实际库存',
            width: 'auto',
            prop: 'blance_count',
            sortable: false,
          },
          {
            order: 6,
            label: '可用库存',
            width: 'auto',
            prop: 'depot_stock_num',
            sortable: false,
          },
        ],
        rightTableData: [],
      }
    },
    computed: {
      finallyColumns() {
        let finallyArray = []
        this.checkList.forEach((checkItem) => {
          finallyArray.push(
            this.columns.filter((item) => item.label === checkItem)[0]
          )
        })
        console.log(finallyArray)
        return _.sortBy(finallyArray, (item) => item.order)
      },
      finallyColumns2() {
        let finallyArray = []
        this.cloItem.forEach((checkItem) => {
          finallyArray.push(
            this.columns2.filter((item) => item.label === checkItem)[0]
          )
        })
        return _.sortBy(finallyArray, (item) => item.order)
      },
    },
    watch: {
      time(val) {
        if (val) {
          this.form.start_time = val[0]
          this.form.end_time = val[1]
        } else {
          this.form.start_time = ''
          this.form.end_time = ''
        }
      },
      dialogFormVisible(val) {
        if (val) {
          this.getLeftTableData()
          // this.rightTableFetchData()
        } else {
          this.cancelid = ''
          this.endId = []
          this.checkedList = []
          this.rightTableData = []
        }
      },
    },
    created() {
      // this.rightTableFetchData()
      // 获取仓库下拉,业务员下拉,线路下拉
      this.getAllList()
    },
    methods: {
      // 获取左侧表格数据
      async getLeftTableData() {
        console.log('getLeftTableData()')
        this.listLoading = true
        const { data, totalCount } = await getAdvanceOrderTableList({
          bill_status: 1,
          pageSize: -1,
          is_pai: 1,
        })
        console.log(data)
        this.leftTableData = data
        this.total = totalCount
        this.listLoading = false
      },
      // 获取处理右侧表格数据
      // async rightTableFetchData() {
      //   // 处理v-model司机
      //   this.rightTableData.forEach((item) => {
      //     this.$set(item, 'deliver', '')
      //     this.$set(item, 'carCode', '')
      //   })
      //   // deliver
      //   // depot_carno
      // },
      chooseDeliver(e, deliverId) {
        console.log(e)
        console.log(deliverId)
        if (deliverId !== '') {
          let pick = e.arr_deliver.filter((item) => item.id == deliverId)[0]
          console.log(pick.depot_carno)
          e.carCode = pick.depot_carno
        } else {
          e.carCode = ''
        }
      },
      showEdit(row) {
        if (!row) {
          this.title = '添加'
        } else {
          this.title = '编辑'
          this.form = Object.assign({}, row)
        }
        this.dialogFormVisible = true
      },
      async handleQuery() {
        this.listLoading = true
        this.$refs.leftTable.clearSelection()
        let formData = JSON.parse(JSON.stringify(this.form))
        formData.staff_ids = formData.staff_ids
        formData.pageSize = -1
        formData.bill_status = 1
        const { data, totalCount } = await getAdvanceOrderTableList(formData)
        console.log(data)
        this.leftTableData = data
        this.total = totalCount
        this.listLoading = false
      },
      // 勾选按钮
      async check() {
        this.$refs.leftTable.clearSelection()
        console.log('勾选')
        let formData = JSON.parse(JSON.stringify(this.form))
        formData.staff_ids = formData.staff_ids
        console.log(formData)
        if (
          formData.staff_ids == '' &&
          formData.depot_id == '' &&
          formData.start_time == '' &&
          formData.line_id == '' &&
          formData.end_time == ''
        ) {
          this.$refs.leftTable.clearSelection()
          this.$message.error('请输入筛选条件')
        } else {
          Object.assign(formData, { pageSize: -1, bill_status: 1, is_pai: 1 })
          this.listLoading = true
          const { data } = await getAdvanceOrderTableList(formData)
          console.log(data)
          if (data.length == 0) {
            this.$message.error('暂无筛选数据')
            this.$refs.leftTable.clearSelection()
          }
          this.leftTableData.forEach((item) => {
            data.forEach((item2) => {
              if (item.id == item2.id) {
                this.$refs.leftTable.toggleRowSelection(item, true)
              }
            })
          })
          // data.forEach((row) => {
          //   this.$refs.leftTable.toggleRowSelection(row, true)
          // })
          this.listLoading = false
        }
        // if (this.form.chck) {
        //   filterRow.depot_id = this.form.chck
        // } else {
        //   delete filterRow.depot_id
        // }
        // if (this.form.ywy.length != 0) {
        //   filterRow.staff_id = this.form.ywy
        //   console.log(filterRow)
        // } else {
        //   delete filterRow.staff_id
        // }
        // if (this.form.xl) {
        //   filterRow.line_id = this.form.xl
        // } else {
        //   delete filterRow.line_id
        // }
        // if (!filterRow.depot_id && !filterRow.staff_id && !filterRow.line_id) {
        //   this.$refs.leftTable.clearSelection()
        //   this.$message.error('请输入筛选条件')
        //   return false
        // }
        // let a = []
        // console.log(filterRow)
        // if (filterRow.staff_id) {
        //   this.leftTableData.forEach((item) => {
        //     filterRow.staff_id.forEach((staffid) => {
        //       if (
        //         item.staff_id == staffid && filterRow.depot_id == undefined
        //           ? true
        //           : filterRow.depot_id == item.depot_id &&
        //             filterRow.line_id == undefined
        //           ? true
        //           : filterRow.line_id == item.cust_line_id
        //       ) {
        //         a.push(item)
        //       }
        //     })
        //   })
        // } else {
        //   console.log('没选业务员')
        //   this.leftTableData.forEach((item) => {
        //     if (
        //       (filterRow.depot_id == undefined
        //         ? true
        //         : filterRow.depot_id == item.depot_id) &&
        //       (filterRow.line_id == undefined
        //         ? true
        //         : filterRow.line_id == item.cust_line_id)
        //     ) {
        //       a.push(item)
        //     }
        //   })
        // }
        // console.log(a)
        // // let e = this.leftTableData.filter((item) => {
        // //   return (
        // //     (filterRow.depot_id == undefined
        // // ? true
        // // : String(filterRow.depot_id).indexOf(item.depot_id) != -1) &&
        // // (filterRow.line_id == undefined
        // //   ? true
        // //   : String(filterRow.staff_id).indexOf(item.line_id) != -1)
        // //   )
        // // })
        // this.$refs.leftTable.clearSelection()

        // if (a.length == 0) {
        //   this.$message.error('暂无筛选数据')
        // } else {
        //   a.forEach((item) => {
        //     this.$refs.leftTable.toggleRowSelection(item, true)
        //   })
        // }
        // let result = this.leftTableData.filter((item) => {
        //   return keys.every(
        //     (key) => String(filterRow[key]).indexOf(item[key]) != -1
        //   )
        //   // return keys.filter((key) => filterRow[key].indexOf != -1)
        // })
      },
      fetchDataIndex() {
        this.getLeftTableData()
        this.$emit('refresh')
      },
      close() {
        this.$refs['form'].resetFields()
        this.form = this.$options.data().form
        this.dialogFormVisible = false
      },
      async addTable() {
        if (this.checkedList.length == 0) {
          this.$message.error('请勾选数据')
          return false
        }
        this.rightTableData = []
        this.ll = true
        this.showRtable = false
        let rightInfo = []
        let endId = []
        this.checkedList.forEach((item) => {
          endId.push(item.id)
        })
        console.log(endId)
        endId = Array.from(new Set(endId))
        let end = endId.join(',')
        this.allId = end
        let { data, code, msg } = await subRightTable({ order_ids: end })
        if (code == 200) {
          console.log(data, 'right')
          this.rightTableData = data
          this.rightTableData.forEach((item) => {
            this.$set(item, 'deliver', '')
            this.$set(item, 'carCode', '')
          })
          console.log(data)
          data.forEach((item) => {
            // this.cancelid.push(item.)
            // 取消派单
            // this.cancelid += item.order_ids.join(',')
            console.log(item.order_ids)
          })
          this.$message.success('添加成功')
          this.ll = false
        }
      },
      save() {
        let data = []
        this.rightTableData.forEach((item) => {
          console.log(item)
          data.push({
            order_ids: item.order_ids.join(','),
            driver_id: item.deliver,
            depot_id: item.depot_id,
          })
        })
        if (data.length == 0) {
          this.$message.error('请添加数据')
          return false
        }
        this.sumdata = data[0]
        summarySave({ data: JSON.stringify(data) }).then((res) => {
          if (res.code == 200) {
            this.rightTableData = []
            this.getLeftTableData()
            setTimeout(() => {
              this.$message.success('派单成功')

              this.$emit('refresh')
              this.$refs.subDialog.showDialog = true
            }, 800)
            this.subData = res.data

            console.log(this.subData, res.data, '汇总数据')
          }
        })
      },
      // 获取仓库下拉,业务员下拉,线路下拉
      async getAllList() {
        let data1 = await getStaffList()
        let data2 = await clientLineList()
        let data3 = await getAllSelect()
        console.log('业务员')
        console.log(data1)

        console.log('线路')
        console.log(data2)
        console.log('仓库all')
        console.log(data3)

        // linexioala
        let line = await postAction('/baseAdmin/line/index')
        this.lineSelect = line.data
        this.chckList = data3.data.depot
        this.ywyList = data1.data
        this.xlList = data2.data
      },
      setSelectRows(row) {
        // 勾选选框事件
        this.checkedList = row
      },
      saveAlloct() {
        // summarySaveD
        let data = []
        this.rightTableData.forEach((item) => {
          console.log(item)
          data.push({
            order_ids: item.order_ids.join(','),
            driver_id: item.deliver,
            depot_id: item.depot_id,
          })
        })
        this.sumdata = data[0]
        console.log(data)
        if (data.length == 0) {
          this.$message.error('请添加数据')
          return false
        }
        try {
          summarySaveD({ data: JSON.stringify(data) }).then((res) => {
            if (res.code == 200) {
              this.rightTableData = []
              this.getLeftTableData()
              setTimeout(() => {
                this.$message.success('派单并调拨成功')
                this.$refs.subDialog.showDialog = true
              }, 800)
              this.subData = res.data
              this.$emit('refresh')
            }
          })
        } catch (e) {}
      },
      clearR() {
        this.endId = []
        this.rightTableData = []
      },
      handleCheckDetail(row) {
        console.log(row)
        // 查看单号详情
        this.$refs.advanceOrderDetail.showDialog()
        this.$refs.advanceOrderDetail.orderStatus2 = row.bill_status_text
        this.$refs.advanceOrderDetail.id = row.id
      },
    },
  }
</script>
<style lang="scss" scoped>
  .wrapper {
    display: flex;
    justify-content: space-around;
  }
  .left {
    width: 49%;
  }
  .middle {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 2%;
    font-size: 35px;
    text-align: center;
  }
  .right {
    width: 49%;
  }
  .topInput {
    div {
      width: 150px;
      margin-right: 15px;
    }
  }
  .table {
    margin-bottom: 10px;
  }
  .topInfo {
    line-height: 32px;
    color: #606266;
    .inp {
      display: flex;
      justify-content: space-around;
      background-color: #f5f7fa;
    }
  }
</style>
