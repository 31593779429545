<template>
  <el-dialog
    :modal="false"
    :close-on-click-modal="false"
    title="派单成功"
    :visible.sync="showDialog"
    width="800px"
  >
    <div class="wrapper" style="overflow: auto">
      <div
        v-for="(item, index) in datalist"
        :key="index"
        class="item"
        style="
          margin-bottom: 10px;
          overflow: hidden;
          border-bottom: 1px dashed #ccc;
        "
      >
        <div class="top-tip" style="overflow: hidden; line-height: 30px">
          <div style="float: left">
            出货仓库:
            <span>{{ item.depot_name }}</span>
          </div>
          <div style="float: left; margin: 0 15px">
            派送司机:
            <span>{{ item.driver_name }}</span>
          </div>
          <div
            v-if="item.allot_code && item.allot_code !== ''"
            style="float: right"
          >
            调拨单:
            <span>{{ item.allot_code }}</span>
          </div>
        </div>
        <!-- 表格 -->
        <el-table stripe border :data="item.order_list">
          <el-table-column
            align="center"
            prop="bill_code"
            label="单号"
            width="160"
          ></el-table-column>
          <el-table-column
            align="center"
            prop="cust_name"
            label="客户名称"
            width="170"
          ></el-table-column>
          <el-table-column
            align="center"
            prop="cust_address"
            label="地址"
            width="auto"
          ></el-table-column>
        </el-table>
        <el-table stripe border :data="item.goods" style="margin-top: 10px">
          <el-table-column
            align="center"
            prop="goods_name"
            label="商品名称"
            width="auto"
          ></el-table-column>
          <el-table-column
            align="center"
            prop="specs"
            label="规格"
            width="170"
          ></el-table-column>
          <el-table-column
            align="center"
            prop="sale_num"
            label="销售"
            width="160"
          ></el-table-column>
          <el-table-column
            align="center"
            prop="give_num"
            label="赠送"
            width="160"
          ></el-table-column>
          <el-table-column
            align="center"
            prop="sum_num"
            label="合计"
            width="160"
          ></el-table-column>
        </el-table>
        <el-button
          style="float: right; margin-top: 10px; margin-left: 10px"
          type="warning"
          @click="print(item)"
        >
          打印
        </el-button>
      </div>
      <!-- 底部按钮 -->
      <div
        slot="footer"
        class="dialog-footer"
        style="margin-top: 25px; text-align: right"
      >
        <!-- <el-button @click="cancelSave">取消派单</el-button> -->
        <el-button @click="close">关闭</el-button>
      </div>
    </div>
    <!--    打印预览组件-->
    <ruilang-display-dialog
      ref="RuilangDisplayDialog"
      bill_type="LHZ"
      :data_id="1"
      :type="1"
      :summary-params="sumdata"
    ></ruilang-display-dialog>
  </el-dialog>
</template>
<script>
  import { unSendOrder } from '@/api/advanceOrder'
  import RuilangDisplayDialog from '@/baseComponents/ruiLangDesign/ruilangDisplayDialog'
  export default {
    name: 'SubDialog',
    components: { RuilangDisplayDialog },
    props: {
      sumdata: {
        type: {},
        default: () => {},
      },
      datalist: {
        type: Array,
        default: () => [],
      },
      cancelid: {
        type: String,
        default: () => '',
      },
    },
    data() {
      return {
        data_id: '',
        showDialog: false,
        // data: [
        //   {
        //     driver_id: '1',
        //     driver_name: '薛宝钗',
        //     driver_mobile: '18600000001',
        //     depot_name: '仓库一123',
        //     car: '京A88888',
        //     order_list: [
        //       {
        //         bill_code: 'XD20112400015',
        //         cust_name: '联盟超市1',
        //         cust_address: '长安区青园街道广安大街30号',
        //         total_amount: '52.0000',
        //       },
        //       {
        //         bill_code: 'XD20112400016',
        //         cust_name: '联盟超市1',
        //         cust_address: '长安区青园街道广安大街30号',
        //         total_amount: '52.0000',
        //       },
        //       {
        //         bill_code: 'XD20112400018',
        //         cust_name: '银泰国际',
        //         cust_address: '河北省石家庄市长安区青园街道谈南路6-1',
        //         total_amount: '0.0000',
        //       },
        //     ],
        //     goods: [
        //       {
        //         goods_type_text: '销常',
        //         goods_name: '伊利222',
        //         specs: '240ml*16',
        //         min_quantity: 168,
        //         quantity: '3瓶',
        //       },
        //     ],
        //     goods_total: '2中3大',
        //   },
        //   {
        //     driver_id: '5',
        //     driver_name: '55账号',
        //     driver_mobile: '',
        //     depot_name: '误删改车',
        //     car: null,
        //     order_list: [
        //       {
        //         bill_code: 'XD20112500004',
        //         cust_name: '奋斗者便利店',
        //         cust_address: '长安区青园街道广安大街30号',
        //         total_amount: '0.0000',
        //       },
        //     ],
        //     goods: [
        //       {
        //         goods_type_text: '退常品',
        //         goods_name: '伊利222',
        //         specs: '240ml*16',
        //         min_quantity: 1,
        //         quantity: '1瓶',
        //       },
        //     ],
        //     goods_total: '1小',
        //   },
        // ],
      }
    },
    computed: {},
    watch: {
      showDialog(val) {
        // if (val) {
        // } else {
        //   this.data = []
        // }
      },
    },
    created() {},
    mounted() {},
    methods: {
      print(row) {
        console.log('打印')
        this.data_id = 1
        this.$refs.RuilangDisplayDialog.dialogFormVisible = true
      },
      cancelSave() {
        console.log('取消派单')
        unSendOrder({ id: this.cancelid }).then((res) => {
          if (res.code == 200) {
            this.$message.success('取消派单成功')
            this.$emit('refresh-index')
            this.showDialog = false
          }
        })
      },
      close() {
        this.showDialog = false
      },
    },
  }
</script>
<style lang="scss" scoped></style>
