var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        {
          attrs: {
            modal: false,
            "close-on-click-modal": false,
            title: _vm.title,
            top: "5vh",
            visible: _vm.showDialog,
            width: "1380px",
            fullscreen: "",
            "destroy-on-close": "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.showDialog = $event
            },
            close: _vm.close,
          },
        },
        [
          _c("el-alert", {
            staticStyle: {
              width: "86%",
              "margin-top": "-70px",
              "margin-left": "80px",
            },
            attrs: { closable: false, title: _vm.titleTip, type: "success" },
          }),
          _c("div", { staticClass: "orderStatus" }, [
            _vm._v(_vm._s(_vm.orderStatus)),
          ]),
          _c(
            "el-form",
            {
              ref: "form",
              attrs: {
                model: _vm.form,
                rules: _vm.rules,
                "label-width": "80px",
                inline: true,
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "购货方", prop: "cust_name" } },
                [
                  _c(
                    "el-autocomplete",
                    {
                      attrs: {
                        "popper-class": "selectDC",
                        "popper-append-to-body": false,
                        "value-key": "cust_name",
                        "fetch-suggestions": _vm.querySearch,
                      },
                      on: { select: _vm.tableSearch },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (ref) {
                            var item = ref.item
                            return [
                              _c(
                                "div",
                                {
                                  staticClass: "wrap",
                                  staticStyle: { display: "flex" },
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "name",
                                      staticStyle: {
                                        width: "50px",
                                        "margin-right": "120px",
                                      },
                                    },
                                    [_vm._v(" " + _vm._s(item.cust_name) + " ")]
                                  ),
                                  _c("span", { staticClass: "addr" }, [
                                    _vm._v(
                                      _vm._s(item.boss) +
                                        " " +
                                        _vm._s(item.mobile)
                                    ),
                                  ]),
                                ]
                              ),
                            ]
                          },
                        },
                      ]),
                      model: {
                        value: _vm.form.cust_name,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "cust_name", $$v)
                        },
                        expression: "form.cust_name",
                      },
                    },
                    [
                      _c("i", {
                        staticClass: "el-icon-search el-input__icon",
                        attrs: { slot: "suffix" },
                        on: {
                          click: function ($event) {
                            return _vm.ghficonClick()
                          },
                        },
                        slot: "suffix",
                      }),
                    ]
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "联系人", prop: "boss" } },
                [
                  _c("el-input", {
                    attrs: { disabled: "" },
                    model: {
                      value: _vm.form.boss,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.form,
                          "boss",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "form.boss",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  staticClass: "dz",
                  attrs: { label: "地址", prop: "address" },
                },
                [
                  _c("el-input", {
                    staticStyle: { width: "512px", "margin-right": "130px" },
                    attrs: { disabled: "" },
                    model: {
                      value: _vm.form.address,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.form,
                          "address",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "form.address",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "仓库", prop: "allot_depot_name" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { placeholder: "仓库", disabled: _vm.isDepot },
                      on: { change: _vm.changeDepot },
                      model: {
                        value: _vm.form.depot_id,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "depot_id", $$v)
                        },
                        expression: "form.depot_id",
                      },
                    },
                    _vm._l(_vm.allSelectList.depot, function (item) {
                      return _c("el-option", {
                        key: item.id,
                        attrs: { label: item.name, value: item.id },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "送货人", prop: "deliver_name" } },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "200px" },
                      attrs: { placeholder: "送货人", clearable: "" },
                      model: {
                        value: _vm.form.deliver_id,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "deliver_id", $$v)
                        },
                        expression: "form.deliver_id",
                      },
                    },
                    _vm._l(_vm.shrList, function (item) {
                      return _c("el-option", {
                        key: item.id,
                        attrs: { label: item.name, value: item.id },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "业务员", prop: "staff_name" } },
                [
                  _c("el-input", {
                    attrs: { disabled: "" },
                    model: {
                      value: _vm.form.staff_name,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.form,
                          "staff_name",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "form.staff_name",
                    },
                  }),
                ],
                1
              ),
              _vm.followuser.length != 0
                ? _c(
                    "el-form-item",
                    { attrs: { label: "随车人" } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: {
                            placeholder: "随车人员",
                            clearable: "",
                            multiple: "",
                          },
                          model: {
                            value: _vm.form.follow_users,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "follow_users", $$v)
                            },
                            expression: "form.follow_users",
                          },
                        },
                        _vm._l(_vm.followuser, function (item_) {
                          return _c("el-option", {
                            key: item_.id,
                            attrs: { label: item_.user_name, value: item_.id },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.listLoading,
                  expression: "listLoading",
                },
              ],
              ref: "tableSort",
              attrs: {
                stripe: "",
                data: _vm.list,
                height: "600px",
                "row-class-name": _vm.tableRowClassName,
              },
              on: {
                "selection-change": _vm.setSelectRows,
                "cell-click": _vm.cellClick,
              },
            },
            [
              _c("el-table-column", {
                attrs: { align: "center", type: "selection", width: "90" },
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "",
                  align: "center",
                  label: "序号",
                  "min-width": "50px",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var $index = ref.$index
                      return [_c("span", [_vm._v(_vm._s($index + 1))])]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "type",
                  align: "center",
                  label: "类型",
                  "min-width": "130px",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [
                        row.index === _vm.tabClickIndex &&
                        _vm.tabClickLabel === "类型"
                          ? [
                              _c(
                                "el-select",
                                {
                                  staticStyle: { width: "100px" },
                                  attrs: {
                                    placeholder: "选择类型",
                                    disabled:
                                      (row.goods_type != 1 &&
                                        row.goods_type != 2 &&
                                        row.goods_type != 10 &&
                                        row.goods_type != 11 &&
                                        row.goods_type != 6 &&
                                        row.goods_type != 17 &&
                                        row.goods_type != 5) ||
                                      row.activity_type != 0,
                                  },
                                  on: {
                                    change: function ($event) {
                                      _vm.utilsF(),
                                        _vm.handleTypeChange($event, row)
                                    },
                                  },
                                  model: {
                                    value: row.goods_type,
                                    callback: function ($$v) {
                                      _vm.$set(row, "goods_type", $$v)
                                    },
                                    expression: "row.goods_type",
                                  },
                                },
                                _vm._l(row.arr_goods_type, function (item_) {
                                  return _c("el-option", {
                                    key: item_.id,
                                    attrs: {
                                      label: item_.name,
                                      value: item_.id,
                                    },
                                  })
                                }),
                                1
                              ),
                            ]
                          : _c("div", [
                              _vm._v(" " + _vm._s(row.type) + " "),
                              _c("i", {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: !row.type,
                                    expression: "!row.type",
                                  },
                                ],
                                staticClass: "el-icon-caret-right trsher",
                              }),
                            ]),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "goods_name_print",
                  align: "center",
                  label: "商品名称",
                  "min-width": "210px",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      var $index = ref.$index
                      return [
                        row.index === _vm.tabClickIndex &&
                        _vm.tabClickLabel === "商品名称" &&
                        (row.activity_type == 0 || row.activity_type == 4)
                          ? [
                              _c("goods-search", {
                                attrs: {
                                  "f-key": row.goods_name,
                                  rowf: row,
                                  "cust-id": _vm.form.cust_id,
                                  "depot-id": _vm.form.depot_id,
                                },
                                on: {
                                  "add-rows": _vm.addRows,
                                  "select-goods-all": function ($event) {
                                    return _vm.selectGoods($event, row, $index)
                                  },
                                  "change-kouwei": function ($event) {
                                    return _vm.changeKouwei($event, row)
                                  },
                                },
                              }),
                            ]
                          : _c("span", [
                              _vm._v(" " + _vm._s(row.goods_name_print) + " "),
                              row.is_abnormal_type == 1 && row.is_abnormal == 2
                                ? _c(
                                    "span",
                                    { staticStyle: { color: "#f60" } },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(row.is_abnormal_type_text) +
                                          " "
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                              _c("i", {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: !row.goods_name_print,
                                    expression: "!row.goods_name_print",
                                  },
                                ],
                                staticClass: "el-icon-search",
                                staticStyle: { "text-align": "right" },
                              }),
                            ]),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "specs",
                  align: "center",
                  label: "规格",
                  width: "110px",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "unit_code",
                  align: "center",
                  label: "条码",
                  "min-width": "110px",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [_vm._v(" " + _vm._s(_vm.currUnitCode(row)) + " ")]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "type",
                  align: "center",
                  label: "单位",
                  width: "140px",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [
                        row.index === _vm.tabClickIndex &&
                        _vm.tabClickLabel === "单位" &&
                        (row.activity_type == 0 || row.activity_type == 4)
                          ? [
                              _c(
                                "el-select",
                                {
                                  staticStyle: { width: "70px" },
                                  attrs: { placeholder: "选择单位" },
                                  on: {
                                    change: function ($event) {
                                      _vm.unitChange($event, row), _vm.utilsF()
                                    },
                                  },
                                  model: {
                                    value: row.unit_id,
                                    callback: function ($$v) {
                                      _vm.$set(row, "unit_id", $$v)
                                    },
                                    expression: "row.unit_id",
                                  },
                                },
                                _vm._l(row.arr_unit, function (item) {
                                  return _c("el-option", {
                                    key: item.id,
                                    attrs: {
                                      label: item.unit_name,
                                      value: item.id,
                                    },
                                  })
                                }),
                                1
                              ),
                            ]
                          : _c("div", [
                              _vm._v(" " + _vm._s(row.unit_name) + " "),
                              _c("i", {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: !row.unit_name,
                                    expression: "!row.unit_name",
                                  },
                                ],
                                staticClass: "el-icon-caret-right trsher",
                              }),
                            ]),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "goods_price",
                  align: "center",
                  label: "单价",
                  "min-width": "110px",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [
                        row.index === _vm.tabClickIndex &&
                        _vm.tabClickLabel === "单价" &&
                        (row.activity_type == 0 ||
                          row.activity_type == 2 ||
                          row.activity_type == 3 ||
                          row.activity_type == 4)
                          ? [
                              _c("el-input", {
                                staticStyle: { width: "80px" },
                                attrs: {
                                  disabled: _vm.isEditPrice == 0,
                                  onkeyup:
                                    "value=value.replace(/[^\\d.]/g,'') ",
                                },
                                on: { input: _vm.utilsF },
                                model: {
                                  value: row.goods_price,
                                  callback: function ($$v) {
                                    _vm.$set(row, "goods_price", $$v)
                                  },
                                  expression: "row.goods_price",
                                },
                              }),
                            ]
                          : _c("div", [
                              _vm._v(" " + _vm._s(row.goods_price) + " "),
                              _c("i", {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: !row.goods_price,
                                    expression: "!row.goods_price",
                                  },
                                ],
                                staticClass: "el-icon-caret-right trsher",
                              }),
                            ]),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "quantity",
                  align: "center",
                  label: "订购数量",
                  "min-width": "110px",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [
                        row.index === _vm.tabClickIndex &&
                        _vm.tabClickLabel === "订购数量" &&
                        (row.activity_type == 0 ||
                          row.activity_type == 2 ||
                          row.activity_type == 3 ||
                          row.activity_type == 4)
                          ? [
                              _c("el-input", {
                                staticStyle: { width: "80px" },
                                on: { input: _vm.utilsF },
                                model: {
                                  value: row.pre_quantity,
                                  callback: function ($$v) {
                                    _vm.$set(row, "pre_quantity", $$v)
                                  },
                                  expression: "row.pre_quantity",
                                },
                              }),
                            ]
                          : _c("div", [
                              _vm._v(" " + _vm._s(row.pre_quantity) + " "),
                              _c("i", {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: !row.pre_quantity,
                                    expression: "!row.pre_quantity",
                                  },
                                ],
                                staticClass: "el-icon-caret-right trsher",
                              }),
                            ]),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "balance_count",
                  align: "center",
                  label: "可用库存",
                  "min-width": "110px",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [
                        _vm._v(" " + _vm._s(row.depot_stock_num_text) + " "),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "depot_stock_num",
                  align: "center",
                  label: "实际库存",
                  "min-width": "110px",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [
                        _vm._v(" " + _vm._s(row.balance_count_text) + " "),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "goods_money",
                  align: "center",
                  label: "金额",
                  "min-width": "90px",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [
                        row.goods_type == 2 ||
                        row.goods_type == 10 ||
                        row.goods_type == 11 ||
                        row.goods_type == 3 ||
                        row.goods_type == 9 ||
                        row.goods_type == 12 ||
                        row.goods_type == 13 ||
                        row.goods_type == 16
                          ? _c("div", [_vm._v(" 0 ")])
                          : _c("div", [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    Number(
                                      (row.pre_quantity || 0) *
                                        (row.goods_price || 0)
                                    ).toFixed(2) || 0
                                  ) +
                                  " "
                              ),
                            ]),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "goods_production_date",
                  align: "center",
                  label: "生产日期",
                  "min-width": "140px",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [
                        row.index === _vm.tabClickIndex &&
                        _vm.tabClickLabel === "生产日期" &&
                        (row.activity_type == 0 || row.activity_type == 4)
                          ? _c("el-date-picker", {
                              staticStyle: { width: "130px" },
                              attrs: { "value-format": "yyyy-MM-dd" },
                              model: {
                                value: row.goods_production_date,
                                callback: function ($$v) {
                                  _vm.$set(row, "goods_production_date", $$v)
                                },
                                expression: "row.goods_production_date",
                              },
                            })
                          : _c("div", [
                              _vm._v(
                                " " + _vm._s(row.goods_production_date) + " "
                              ),
                              _c("i", {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: !row.goods_production_date,
                                    expression: "!row.goods_production_date",
                                  },
                                ],
                                staticClass: "el-icon-caret-right trsher",
                              }),
                            ]),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "info",
                  align: "center",
                  label: "备注",
                  "min-width": "110px",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [
                        row.index === _vm.tabClickIndex &&
                        _vm.tabClickLabel === "备注" &&
                        (row.activity_type == 0 || row.activity_type == 4)
                          ? _c("el-input", {
                              model: {
                                value: row.info,
                                callback: function ($$v) {
                                  _vm.$set(row, "info", $$v)
                                },
                                expression: "row.info",
                              },
                            })
                          : _c("div", [
                              _vm._v(" " + _vm._s(row.info) + " "),
                              _c("i", {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: !row.info,
                                    expression: "!row.info",
                                  },
                                ],
                                staticClass: "el-icon-caret-right trsher",
                              }),
                            ]),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  align: "center",
                  label: "操作",
                  fixed: "right",
                  width: "160",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var $index = ref.$index
                      var row = ref.row
                      return [
                        _vm.edit && row.activity_type == 0
                          ? _c(
                              "div",
                              [
                                _c(
                                  "el-button",
                                  {
                                    attrs: { type: "text" },
                                    nativeOn: {
                                      click: function ($event) {
                                        $event.preventDefault()
                                        return _vm.copyRow($index, row)
                                      },
                                    },
                                  },
                                  [_vm._v(" 复制 ")]
                                ),
                                _c(
                                  "el-button",
                                  {
                                    attrs: { type: "text" },
                                    nativeOn: {
                                      click: function ($event) {
                                        $event.preventDefault()
                                        return _vm.deleteRow($index, row)
                                      },
                                    },
                                  },
                                  [_vm._v(" 删除 ")]
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _c(
            "el-form",
            {
              ref: "form",
              staticStyle: { "margin-top": "15px" },
              attrs: { model: _vm.form, "label-width": "120px", inline: true },
            },
            [
              _c(
                "div",
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "备注", prop: "remark" } },
                    [
                      _c("el-input", {
                        staticStyle: { width: "530px" },
                        model: {
                          value: _vm.form.remark,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "remark", $$v)
                          },
                          expression: "form.remark",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "客户签名", prop: "" } },
                    [
                      _vm.form.sign_url
                        ? _c("el-image", {
                            staticStyle: { height: "36px" },
                            attrs: {
                              "preview-src-list": [_vm.form.sign_url],
                              src: _vm.form.sign_url,
                            },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "订购总额", prop: "total_amount" } },
                [
                  _c("el-input", {
                    attrs: { disabled: "" },
                    model: {
                      value: _vm.form.total_amount,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.form,
                          "total_amount",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "form.total_amount",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "退货金额", prop: "refund_goods_amount" } },
                [
                  _c("el-input", {
                    attrs: { disabled: "" },
                    model: {
                      value: _vm.form.refund_goods_amount,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.form,
                          "refund_goods_amount",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "form.refund_goods_amount",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "销售金额", prop: "sale_amount" } },
                [
                  _c("el-input", {
                    attrs: { disabled: "" },
                    model: {
                      value: _vm.form.sale_amount,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.form,
                          "sale_amount",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "form.sale_amount",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "优惠", prop: "discount_amount" } },
                [
                  _c("el-input", {
                    on: { input: _vm.yhInputF },
                    model: {
                      value: _vm.form.discount_amount,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.form,
                          "discount_amount",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "form.discount_amount",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "优惠后", prop: "discounted_amount" } },
                [
                  _c("el-input", {
                    model: {
                      value: _vm.form.discounted_amount,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.form,
                          "discounted_amount",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "form.discounted_amount",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "退货退款", prop: "refund_amount" } },
                [
                  _c("el-input", {
                    attrs: { disabled: "" },
                    model: {
                      value: _vm.form.refund_amount,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.form,
                          "refund_amount",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "form.refund_amount",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "退货方式", prop: "refund_type_text" } },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "186px" },
                      attrs: { placeholder: "退货方式" },
                      on: { change: _vm.utilsF },
                      model: {
                        value: _vm.form.refund_type,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "refund_type", $$v)
                        },
                        expression: "form.refund_type",
                      },
                    },
                    _vm._l(_vm.allSelectList.arr_refund_type, function (item) {
                      return _c("el-option", {
                        key: item.id,
                        attrs: { label: item.name, value: item.id },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _vm.form.is_fun
                ? _c(
                    "el-form-item",
                    { attrs: { label: "费用抵扣", prop: "thtk" } },
                    [
                      _c("el-input", {
                        model: {
                          value: _vm.form.support_amount,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.form,
                              "support_amount",
                              typeof $$v === "string" ? $$v.trim() : $$v
                            )
                          },
                          expression: "form.support_amount",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.form.is_fun
                ? _c(
                    "el-form-item",
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "186px" },
                          attrs: {
                            placeholder:
                              "请选费用" + "（合计" + _vm.funds_zong + "）",
                            multiple: "",
                            "collapse-tags": "",
                          },
                          on: { change: _vm.handlerfydk },
                          model: {
                            value: _vm.form.fun_ids,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "fun_ids", $$v)
                            },
                            expression: "form.fun_ids",
                          },
                        },
                        _vm._l(_vm.funselect, function (item_) {
                          return _c("el-option", {
                            key: item_.id,
                            attrs: {
                              label:
                                item_.funds_type +
                                "(金额" +
                                item_.left_amount +
                                ")",
                              value: item_.id,
                            },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.style == 2
                ? _c(
                    "el-form-item",
                    { attrs: { label: "兑奖现金:", prop: "prize_amount" } },
                    [
                      _c("el-input", {
                        on: { input: _vm.rehandler },
                        model: {
                          value: _vm.form.prize_amount,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.form,
                              "prize_amount",
                              typeof $$v === "string" ? $$v.trim() : $$v
                            )
                          },
                          expression: "form.prize_amount",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.style == 3
                ? _c(
                    "el-form-item",
                    { attrs: { label: "兑付现金:", prop: "cash_amount" } },
                    [
                      _c("el-input", {
                        on: { input: _vm.rehandler },
                        model: {
                          value: _vm.form.cash_amount,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.form,
                              "cash_amount",
                              typeof $$v === "string" ? $$v.trim() : $$v
                            )
                          },
                          expression: "form.cash_amount",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "el-form-item",
                { attrs: { label: "应收款", prop: "receiv_money" } },
                [
                  _c("el-input", {
                    attrs: { disabled: "" },
                    model: {
                      value: _vm.form.receiv_money,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.form,
                          "receiv_money",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "form.receiv_money",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  staticStyle: { width: "60%" },
                  attrs: { label: "结算方式", prop: "account_type" },
                },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "186px" },
                      attrs: { placeholder: "结算方式" },
                      model: {
                        value: _vm.form.account_type,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "account_type", $$v)
                        },
                        expression: "form.account_type",
                      },
                    },
                    _vm._l(_vm.accounttype, function (item) {
                      return _c("el-option", {
                        key: item.id,
                        attrs: { label: item.name, value: item.id },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _vm._l(_vm.xssk, function (item1, index) {
                return _c(
                  "el-form-item",
                  { key: index, attrs: { label: "销售收款" } },
                  [
                    _c(
                      "div",
                      { staticStyle: { display: "flex" } },
                      [
                        _c("el-input", {
                          staticStyle: {
                            width: "90px",
                            "margin-right": "20px",
                          },
                          model: {
                            value: item1.money,
                            callback: function ($$v) {
                              _vm.$set(
                                item1,
                                "money",
                                typeof $$v === "string" ? $$v.trim() : $$v
                              )
                            },
                            expression: "item1.money",
                          },
                        }),
                        _c(
                          "el-select",
                          {
                            staticStyle: { width: "90px" },
                            model: {
                              value: item1.pay_type,
                              callback: function ($$v) {
                                _vm.$set(item1, "pay_type", $$v)
                              },
                              expression: "item1.pay_type",
                            },
                          },
                          _vm._l(
                            _vm.allSelectList.arr_pay_type,
                            function (item2) {
                              return _c("el-option", {
                                key: item2.id,
                                attrs: { label: item2.name, value: item2.id },
                              })
                            }
                          ),
                          1
                        ),
                      ],
                      1
                    ),
                    index == 0
                      ? _c("i", {
                          staticClass: "el-icon-circle-plus addbtn",
                          on: { click: _vm.addInput },
                        })
                      : _vm._e(),
                  ]
                )
              }),
            ],
            2
          ),
          _c(
            "el-table",
            { attrs: { stripe: "", data: _vm.bottomTable } },
            [
              _c("el-table-column", {
                attrs: {
                  prop: "pay_type_name",
                  label: "收款方式",
                  width: "",
                  align: "center",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "amount",
                  label: "金额",
                  width: "",
                  align: "center",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "user_name",
                  label: "收款人",
                  width: "",
                  align: "center",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "create_at",
                  label: "时间",
                  width: "",
                  align: "center",
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.handleReview } },
                [_vm._v("审核")]
              ),
              _c("el-button", { on: { click: _vm.close } }, [_vm._v("取 消")]),
            ],
            1
          ),
        ],
        1
      ),
      _c("input-window", {
        ref: "inputWindow",
        on: { "change-client": _vm.changeClient },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }