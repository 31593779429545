<template>
  <el-dialog
    :modal="false"
    title="到货结算"
    :visible.sync="showDialog"
    width="500px"
    top="30vh"
    :close-on-click-modal="false"
    destroy-on-close
    center
  >
    <div class="orderTest-container">
      <el-form ref="form" :model="form" inline label-width="110px">
        <el-form-item label="订单应收款">
          <el-input :value="data.a" disabled style="width: 120px"></el-input>
        </el-form-item>
        <div></div>
        <el-form-item label="销售已收款">
          <el-input :value="data.b" disabled style="width: 120px"></el-input>
        </el-form-item>

        <el-form-item label="派送收款">
          <div v-for="(item, index) in list" :key="index">
            <el-input v-model="item.amount" style="width: 120px"></el-input>
            <el-select
              v-model="item.pay_type"
              style="width: 90px; margin: 0 0 10px 10px"
            >
              <el-option
                v-for="item_1 in arr_pay_type"
                :key="item_1.id"
                :label="item_1.name"
                :value="item_1.id"
              ></el-option>
            </el-select>
            <i
              v-if="index == 0"
              class="el-icon-circle-plus addbtn"
              @click="addInput"
            ></i>
          </div>
        </el-form-item>
      </el-form>
    </div>
    <!-- 底部按钮 -->
    <div
      slot="footer"
      class="dialog-footer"
      style="margin-top: 5px; text-align: right"
    >
      <el-button type="primary" @click="save">确定</el-button>
      <el-button @click="close">取 消</el-button>
    </div>
  </el-dialog>
</template>
<script>
  import { getAllSelect } from '@/api/advanceOrder'
  export default {
    name: 'ArrivalSun',
    components: {},
    props: {
      data: {
        type: Object,
        default: () => {},
      },
    },
    data() {
      return {
        showDialog: false,
        form: {},
        arr_pay_type: [],
        list: [{ pay_type: this.defaultC, amount: '0' }],
      }
    },
    computed: {
      defaultC() {
        return this.arr_pay_type[0].id
      },
    },
    watch: {
      showDialog(val) {
        if (!val) {
          this.list = [{ pay_type: this.defaultC, amount: '0' }]
        } else {
          let defaultM = Number(this.data.a) - Number(this.data.b)
          this.list = [{ pay_type: this.defaultC, amount: defaultM.toFixed(2) }]
        }
      },
    },
    created() {
      this.getAllSelectList()
    },
    mounted() {},
    methods: {
      async getAllSelectList() {
        let { data, code, msg } = await getAllSelect()
        if (code == 200) {
          console.log('所有下拉列表2')
          console.log(data)
          this.arr_pay_type = data.arr_pay_type
        }
      },
      close() {
        this.showDialog = false
      },
      save() {
        this.$emit('save-end', this.list)
      },
      addInput() {
        this.list.push({
          pay_type: this.defaultC,
          amount: '0',
        })
      },
    },
  }
</script>
<style lang="scss" scoped>
  .addbtn {
    position: absolute;
    top: 0;
    right: -50px;
    margin-left: 15px;
    font-size: 32px;
    color: #84bc39;
  }
</style>
