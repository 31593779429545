import { export_json_to_excel } from '@/utils/excel'

function formatJson(filterVal, jsonData) {
  return jsonData.map((v) => {
    return filterVal.map((j) => {
      return v[j]
    })
  })
}
export function exportExcel(
  headers = [],
  list = [],
  columns = [],
  isIdx = true,
  filename = '导出文件'
) {
  const cols = JSON.parse(JSON.stringify(columns))
  const header = JSON.parse(JSON.stringify(headers))
  console.log(header)

  console.log(cols)

  if (isIdx) {
    cols.unshift({
      label: '序号',
      prop: 'idx',
    })
  }
  const props = []
  header.forEach((item, i) => {
    cols.forEach((item2, idx) => {
      if (item2.label == item) {
        props.push(item2.prop)
      }
    })
  })
  if (header.length != props.length) {
    console.error('数据传入错误')
    return false
  }
  const data = formatJson(props, list)
  console.log(props)

  console.log(data)
  if (isIdx) {
    header.unshift('序号')
    props.unshift('idx')
    data.forEach((item, index) => {
      item.unshift(index + 1)
    })
  }
  export_json_to_excel({
    header,
    data,
    filename,
    autoWidth: true,
    bookType: 'xlsx',
  })
}
