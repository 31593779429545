var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        modal: false,
        "close-on-click-modal": false,
        title: "订单汇总",
        visible: _vm.dialogFormVisible,
        width: "860px",
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogFormVisible = $event
        },
        close: _vm.close,
      },
    },
    [
      _vm._l(_vm.sumlist, function (list, index) {
        return _c(
          "div",
          {
            key: index,
            staticStyle: { "border-bottom": "#2e2f34 1px dashed" },
          },
          [
            _c("h3", [_vm._v(_vm._s(list.depot_name))]),
            _c(
              "el-table",
              {
                staticStyle: { width: "100%" },
                attrs: { data: list.order_list, border: "" },
              },
              [
                _c("el-table-column", { attrs: { type: "index" } }),
                _c("el-table-column", {
                  attrs: { prop: "bill_code", label: "单号", align: "center" },
                }),
                _c("el-table-column", {
                  attrs: {
                    prop: "cust_name",
                    label: "客户名称",
                    align: "center",
                  },
                }),
                _c("el-table-column", {
                  attrs: {
                    prop: "cust_address",
                    label: "地址",
                    align: "center",
                  },
                }),
                _c("el-table-column", {
                  attrs: {
                    prop: "cust_boss",
                    label: "联系人",
                    align: "center",
                  },
                }),
              ],
              1
            ),
            _c(
              "el-table",
              {
                staticStyle: { width: "100%" },
                attrs: { data: list.goods, border: "" },
              },
              [
                _c("el-table-column", { attrs: { type: "index" } }),
                _c("el-table-column", {
                  attrs: {
                    prop: "goods_name",
                    label: "商品名称",
                    align: "center",
                  },
                }),
                _c("el-table-column", {
                  attrs: { prop: "specs", label: "规格", align: "center" },
                }),
                _c("el-table-column", {
                  attrs: {
                    align: "center",
                    prop: "sale_num",
                    label: "销售",
                    width: "160",
                  },
                }),
                _c("el-table-column", {
                  attrs: {
                    align: "center",
                    prop: "give_num",
                    label: "赠送",
                    width: "160",
                  },
                }),
                _c("el-table-column", {
                  attrs: {
                    align: "center",
                    prop: "sum_num",
                    label: "合计",
                    width: "160",
                  },
                }),
              ],
              1
            ),
            _c(
              "el-button",
              {
                staticStyle: { margin: "10px 0px" },
                attrs: { type: "warning" },
                on: {
                  click: function ($event) {
                    return _vm.handlerdayin(list.depot_id)
                  },
                },
              },
              [_vm._v(" 打印 ")]
            ),
          ],
          1
        )
      }),
      _c("ruilang-display-dialog", {
        ref: "RuilangDisplayDialog",
        attrs: {
          bill_type: "LHZ",
          data_id: 1,
          "summary-params": _vm.dataform,
          type: 1,
        },
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }